import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './App.css';
import Dashboard from './containers/Dashboard';
import DevPanel from './containers/Dev Panel';
import Orders from './containers/Orders';
import PaymentMethods from './containers/Payment Methods';
import ProductDetails from './containers/Product Details';
import ProfileSettings from './containers/Profile Settings';
import SignIn from './containers/SignIn';
import Store from './containers/Store';
import { CapabilitiesPage, Company, Enterprise, Home, Legal, Port1, Port2, Port3, Port4, Port5, Port6, PP, TOS} from './pages';
import CaseStudies from './pages/case-studies/case-studies';
import ContactPage from './pages/contact/contact';
import Create from './pages/create/create';
import NextGen from './pages/next-gen/next-gen';
import Preorder from './pages/preorder/preorder';
import Sitemap from './pages/sitemap/sitemap';
import Social from './pages/social/social';
import Spacepage from './pages/space-page/spacepage';
import SpaceTrailer from './pages/space-trailer/space-trailer';
import Start from './pages/start/start';
import ThankYou from './pages/thank-you/thank-you';
import Welcome from './pages/welcome/welcome';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/case-studies/physical-therapy" element={<Port6 />} />
        <Route path="/case-studies/aurora-mail" element={<Port5 />} />
        <Route path="/case-studies/vmcrystalware" element={<Port4 />} />
        <Route path="/case-studies/charm" element={<Port3 />} />
        <Route path="/case-studies/amg-mercedes" element={<Port2 />} />
        <Route path="/case-studies/cashcat-streaming" element={<Port1 />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/legal/privacy-policy" element={<PP />} />
        <Route path="/legal/terms-of-service" element={<TOS />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/support" element={<ContactPage />} />
        <Route path="/company" element={<Company />} />
        <Route path="/next-gen" element={<NextGen />} />
        <Route path="/waitlist" element={<Home />} />
        <Route path="/how-it-works" element={<CapabilitiesPage />} />
        {/* <Route path="/store" element={<Store />} /> */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/why-choose-etheware" element={<Welcome />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/apps-of-the-year" element={<CaseStudies />} />
        <Route path="/socials" element={<Social />} />
        <Route path="/start" element={<Start />} />
        <Route path="/preorder" element={<Preorder />} />
        <Route path="/space-trailer" element={<SpaceTrailer />} />
        <Route path="/" element={<Enterprise />} />
        <Route path="/create" element={<Create />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile-settings" element={<ProfileSettings />} />
        <Route path="/store" element={<Store />} />
        <Route path="/payment-methods" element={<PaymentMethods />} />
        <Route path="/order-history" element={<Orders />} />
        <Route path="/dev-panel" element={<DevPanel />} />
        <Route path="/product-details" element={<ProductDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
