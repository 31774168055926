import React from 'react';
import { Navbar } from '../../components';
import { Footer } from '../../containers';
import "./sitemap.css";

const Sitemap = () => {
  return (
    <div className="sitemap">
        <Navbar />
        <div className="sitemap__container">
            <div className="sitemap__header">
                <h1 className="h1">Etheware Site Map</h1>
            </div>
            <div className="sitemap__links">
                <div className="sitemap-links__div">
                    <h4>About Etheware</h4>
                    <p><a href="/company">What is Etheware</a></p>
                    <p><a href="/contact">Contact Etheware</a></p>
                    <p><a href="https:www.twitter.com/ethewaretech">Twitter</a></p>
                    <p><a href="/https:www.instagram.com/ethewaretech">Instagram</a></p>
                    <p><a href="/legal">Legal</a></p>
                </div>
                <div className="sitemap-links__div">
                    <h4>Where to Buy</h4>
                    <p><a href="/store">Shop Online</a></p>
                    <p><a href="/why-buy-etheware">Why Buy Products from Etheware</a></p>
                </div>
                <div className="sitemap-links__div">
                    <h4>Account</h4>
                    <p><a href="/profile-settings">Manage Your Etheware ID</a></p>
                    <p><a href="/sign-in">Etheware Store Account</a></p>
                    <p><a href="/sign-up">Create an Account</a></p>
                    <p><a href="/dashboard">Dashboard</a></p>
                </div>

                <div className="sitemap-links__div">
                    <h4>Store Services</h4>
                    <p><a href="/case-studies">Case Studies</a></p>
                    <p><a href="/only-at-etheware">Only at Etheware</a></p>
                    <p><a href="/trade-in">Etheware Trade In</a></p>
                </div>
                <div className="sitemap-links__div">
                    <h4>Shop and Learn</h4>
                    <p><a href="/store">Store</a></p>
                    <p><a href="/product-details?service=websites&plan=earth">Earth</a></p>
                    <p><a href="/product-details?service=websites&plan=bronze">Bronze</a></p>
                    <p><a href="/product-details?service=websites&plan=silver">Silver</a></p>
                    <p><a href="/product-details?service=websites&plan=gold">Gold</a></p>
                    <p><a href="/product-details?service=websites&plan=diamond-peak">Diamond Peak</a></p>
                    <p><a href="/product-details?service=websites&plan=gold">Platinum Unlimited</a></p>
                </div>
                <div className="sitemap-links__div">
                    <h4>Support</h4>
                    <p><a href="/support">Etheware Support</a></p>
                </div>
                <div className="sitemap-links__div">
                    <h4>Etheware Information</h4>
                    <p><a href="/eon">Etheware Eon</a></p>
                    <p><a href="/etheware-difference">The Etheware Difference</a></p>
                    <p><a href="/how-it-works">How It Works</a></p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Sitemap