import React, { useRef, useEffect } from 'react';
import { Col } from 'reactstrap'
import { init } from 'ityped';
import "./thank-you.css";
import Demo from '.././../assets/demo.png'
import { Link } from 'react-router-dom';

const ThankYou = () => {
    const superRef = useRef()
    useEffect(()=>{
        init(superRef.current, {
            backDelay: 1200,
            showCursor: true,
            strings:[
                'Blueprinting & UI/UX Design',
                'Research & Development',
                "Security & Hosting",
                "Blockchain Solutions",
                'AI Powered Services',
                'Updates and Maintenance', 
                'Marketing & SEO', 
                "Release & Version Control"
            ]
        })
    }, [])
    return <section className='super__section' id="home">
        <Col d-flex flex-row lg='6' md='6' w="6" > 
            <div className='super__content'>
                <p2 className='mb-20'>Thank you for your interest in developing an application with us.</p2>
                <h3 className='mb-4'>Etheware is here to Guide you  </h3>
                <h2 className='super__title mb-5'>Through <span ref={superRef}></span></h2>
                <p>An Etheware Representative is reviewing your profile 
                and will contact you shortly to better discuss your project requirements. In the meantime,
                take a look around your dashboard, and get to know your Etheware control room.
                </p>
            </div>
            <div className='super__btns d-flex align-items-center gap-4'>
                <Link to="/dashboard"><button className='btn-contact_btn'>My Dashboard</button></Link>
            </div>
        </Col>
        <Col d-flex lg='0' md='1'>
            <Col lg="4" md="5" sm="15">
            <img 
                className='ty__img'
                src={Demo} 
                alt="Etheware Project Demo"
                loading="lazy"
            />
                
            </Col>
        </Col>
  </section>
}

export default ThankYou