import React from 'react';
import { CTA, Navbar } from '../../components';
import { Comp, Execution, Footer} from '../../containers';
import Textbox from '../../components/textBox/textBox';

const CapabilitiesPage = () => {
  return (
      <div className='home'>  
        <Navbar />
            <Textbox  
              title={"The Power of The Mind"}
              text={`"When you are truly clear about what you want, the whole universe stands on tiptoe waiting to assist you in miraculous and amazing ways to manifest your dream or intention." - Constance Arnold`}
            />
            <Execution />
            <Comp />
            <CTA />
            <Footer />
      </div>
  );
}

export default CapabilitiesPage;