import React from 'react';
import { Contact, CTA, LeftMedia, Media, Navbar, ProductImg, TextBox } from '../../components';


const Port1 = () => {
  return (
    <div className='home'>
        <Navbar />
        <Media 
          topline={"The Concept"}
          header={"Business Overview"}
          paragraph={"CashCat is a live streaming service designed for content providers in gaming, entertainment, music, sports, and other areas. The majority of people are connected with Cashcat due to its popularity among live stream entertainment enthusiasts. The reality, though, is somewhat different. People like seeing others engage in activities that they enjoy, such as playing video games, preparing good food, and performing music. This surge in popularity of live streaming was not predicted by many individuals. Cashcat's business strategy works due of its tight-knit communities, which are made up of like-minded people who are enthusiastic about certain themes."}
          text={"CashCat users will have access to over 500,000 different forms of video and material on the site by 2023."}
          lin1={require('../../assets/port1.png')}
        />
        <Media 
          topline={"Accessing CashCat"}
          header={"How Does CashCat Work?"}
          paragraph={"Viewing events on CashCat requires no sign up. Registration for broadcasting and talking is free. 3 CashCat's real-time interactive chatting transforms the virtual environment into a social experience. Many broadcasters create a personal connection with their listeners through this engagement. Broadcasters can also preserve videos indefinitely, making them available for subsequent viewing."}
          text={"CashCat content may be accessed through a range of devices and mediums, including PCs, game consoles, and mobile devices. CashCat provides access via a web browser and specific Cashcat applications, as well as specialist software for enhanced video broadcasting."}
          lin1={require('../../assets/port1-2.png')}
        />
        <LeftMedia
          topline={"Evolve"}
          header={"The Solution"}
          paragraph={"CashCat is set to launch in 2023 and generates revenue through advertisements, subscriptions and watch time. CashCat allows the ability for creators to accept donations of many formats from other users, which can be withdrawn immediately."}
          text={"CashCat provides Snow Leopard membership for $8.99 a month, which entitles users to ad-free viewing of CashCat content as well as other perks."}
          videolink={require('../../assets/port1-3.mp4')}
          buttonlink={'/contact'}
          button={"Get Started"}
        />
        <ProductImg 
            title= {"CashCat"}
            subtitle={"CashCat is primarily known for its role as a live-stream platform, where broadcasting and viewing pre-made or live media is simple. Viewers can donate a multitude of currencies, share comments, and chat with their favorite streamers."}
            imgUrl={require('../../assets/port1-1.png')}
           
        />
        <TextBox
          title={"Outcome"}
          text={"Before creating the platform's UI and UX design, Etheware conducted extensive research and development on what makes the user feel comfortable. In addition, we created a unique design for the platform's wallet, with a new way of sending crypto never seen before in history."}
          subtitle={"During deployment, Etheware rapidly and effectively corrected any issues that arose with the design and data collecting. We successfully completed version one of CashCat after properly resolving the problems and continue to build it out for official release in 2023."}
        />
        <CTA />
        <Contact />
    </div>
  )
}

export default Port1