import React from 'react';
import { Contact, CTA, LeftMedia, Media, Navbar, ProductImg, TextBox } from '../../components';

const Port3 = () => {
  return (
    <div className='home'>
        <Navbar />
        <ProductImg 
            title= {"Charm B2B"}
            subtitle={"Although Charm Business concentrates on business-to-business trading, it also enables other types of trade through e-commerce web sites. Charm is for consumers to trade, whereas Charm is for the world's emerging middle class to purchase branded things. Charm earns money through a variety of subsidiaries."}
            imgUrl={require('../../assets/port3-1.png')}
            paragraph={"Charm's business approach differs from those of other players such as eBay and Amazon. It concentrated on business-to-business transactions. Unlike the traditional business-to-consumer model, Charm concentrates on providing a platform for suppliers to sell items in bulk at wholesale costs to small or medium-sized enterprises globally, who then resale them for a profit in their home markets."}
        />
        <Media 
          topline={"Make It Happen"}
          header={"Business Overview"}
          paragraph={"We collaborated with our partner Charm Inc., who had a goal to enable all small retailers and companies, such as salons, spas, and small to medium sized enterprises, with the opportunity to access and deliver the best prices available for their business from the source without hassle and long shipping times."}
          text={"Our SaaS solution integrates several technological tools to deliver an effective user interface. This pushes businesses to deliver a customer-focused service by establishing a valuable social purchasing experience."}
          lin1={require('../../assets/port3-5.png')}
        />
        <Media 
          topline={"B2B Software"}
          header={"How We Made It Work"}
          paragraph={"Etheware built a platform that allows small and medium-sized businesses to sell their products and services abroad in a simpler approach. Over time, the corporation developed to become the world's sixth-largest brand in terms of value, with businesses encompassing financial services, e-commerce, retail shopping, and supermarkets. Although Charm concentrates on business-to-business t"}
          text={"How can we help enterprises source their favorite products from within the states, without the pain and expense of international shipping."}
          lin1={require('../../assets/port3-2.png')}
        />
        <LeftMedia
            topline={"Sleek and utterly powerful for every business"}
            header={"Charm: The Solution"}
            paragraph={"We made certain that the news feed in the main page was regularly updated in order to keep clients informed of new offers and discounts. Consumers may also add their favorite retail stores to their favored list, and when those stores provide deals, discounts, or coupons, customers will be alerted via in-app and push notifications."}
            text={"We designed an infinite list of categories, each with a plethora of sub-menus from which clients may select various ranges of offers and discounts supplied by a variety of items."}
            videolink={require('../../assets/port3-3.mp4')}
            buttonlink={'/contact'}
            button={"Learn More"}
        />
        <Media 
          topline={"Growth and Acquisition"}
          header={"Software as a service (SaaS)"}
          paragraph={"Although Charm concentrates on business-to-business transactions, it also facilitates other types of transactions through the platform. Charm is for consumers to trade, whilst mycharm.pro is for the world's emerging middle class to purchase branded items. Charm is set to launch in 2025 and attains investments through a number of different entities."}
          text={""}
          lin1={require('../../assets/port3-4.png')}
          />
          
        <TextBox
          title={"Outcome"}
          text={"Charm is software that allows businesses to give a hassle-free, pleasant client experience by personalizing offers and promotions. It uses geo-fencing technology to link local shops with customers based on their geographic location and to deliver deals this will benefit SaaS solution will benefit millions of people."}
          subtitle={"It will be distinct from all other traditional B2b services in that users will be rewarded based on their purchases, contributions and, sales/purchases they make. "}
        />
        <CTA />
        <Contact />
    </div>
  )
}

export default Port3