import React from 'react';
import { Navbar } from '../../components';
import LightModule from '../../components/module/light-module';
import Mini from '../../components/module/mini-module/mini';
import { Footer } from '../../containers';
import Intro from '../../containers/intro/intro';
import Languages from '../../containers/languages/languages';
import Trusted from '../../containers/trusted/trusted';
import Homepage1 from './../../assets/homepage1.png';
import Homepage2 from './../../assets/homepage2.png';
import Homepage3 from './../../assets/homepage3.png';
import Homepage4 from './../../assets/homepage4.png';
import Homepage5 from './../../assets/homepage5.png';
import MDHomepage1 from './../../assets/mdhomepage1.png';
import MDHomepage2 from './../../assets/mdhomepage2.png';
import MDHomepage3 from './../../assets/mdhomepage3.png';
import MDHomepage4 from './../../assets/mdhomepage4.png';
import MDHomepage5 from './../../assets/mdhomepage5.png';
import SMHomepage1 from './../../assets/smhomepage1.png';
import SMHomepage2 from './../../assets/smhomepage2.png';
import SMHomepage3 from './../../assets/smhomepage3.png';
import SMHomepage4 from './../../assets/smhomepage4.png';
import SMHomepage5 from './../../assets/smhomepage5.png';
import MiniHomepage1 from './../../assets/minihomepage1.png';
import MiniHomepage2 from './../../assets/minihomepage2.png';
import MiniHomepage3 from './../../assets/minihomepage3.png';
import MiniHomepage4 from './../../assets/minihomepage4.png';

const Welcome = () => {
  return (
    <div className='home'>
      <Navbar />
      <Intro />
      <LightModule 
        lightmoduleimage={Homepage1}
        mdlightmoduleimage={MDHomepage1}
        smlightmoduleimage={SMHomepage1}
        lightmodule__alt={"Etheware Earth"}
        mdlightmodule__alt={"Etheware Earth"}
        smlightmodule__alt={"Etheware Earth"}
        bttnlink1={"product-details?service=websites&plan=earth"}
      />
      <LightModule 
        lightmoduleimage={Homepage2}
        mdlightmoduleimage={MDHomepage2}
        smlightmoduleimage={SMHomepage2}
        lightmodule__alt={"Etheware Silver"}
        mdlightmodule__alt={"Etheware Silver"}
        smlightmodule__alt={"Etheware Silver"}
        bttnlink1={"product-details?service=websites&plan=silver"}
      />
      <LightModule
        lightmoduleimage={Homepage3}
        mdlightmoduleimage={MDHomepage3}
        smlightmoduleimage={SMHomepage3}
        lightmodule__alt={"Etheware Gold"}
        mdlightmodule__alt={"Etheware Gold"}
        smlightmodule__alt={"Etheware Gold"}
        bttnlink1={"product-details?service=websites&plan=gold"}
      />
      <LightModule
        lightmoduleimage={Homepage4}
        mdlightmoduleimage={MDHomepage4}
        smlightmoduleimage={SMHomepage4}
        lightmodule__alt={"Diamond Peak"}
        mdlightmodule__alt={"Diamond Peak"}
        smlightmodule__alt={"Diamond Peak"}
        bttnlink1={"product-details?service=websites&plan=diamond-peak"}
      />
     <LightModule
        lightmoduleimage={Homepage5}
        mdlightmoduleimage={MDHomepage5}
        smlightmoduleimage={SMHomepage5}
        lightmodule__alt={"Platinum Unlimited"}
        mdlightmodule__alt={"Platinum Unlimited"}
        smlightmodule__alt={"Platinum Unlimited"}
        bttnlink1={"product-details?service=websites&plan=platinum-unlimited"}
      />
    
      <Mini 
        minimoduleimage={MiniHomepage1}
        minimodule__bttn1={"Learn More >"}
        minimodule__bttn2={"Buy >"}
        minibttnlink1={"product-details?service=websites&plan=bronze"}
        minibttnlink2 = {"product-details?service=websites&plan=bronze"}

        miniimage={MiniHomepage2}
        mini__bttn1={"Learn More >"}
        mini__bttn2={"Buy >"}
        mini__link={"product-details?service=websites&plan=contact"}
        mini__link2 = {"product-details?service=websites&plan=contact"}
      />
      <Mini 
        minimoduleimage={MiniHomepage3}
        minimodule__bttn1={"Learn More >"}
        minimodule__bttn2={"Buy >"}
        minibttnlink1={"product-details?service=websites&plan=sign-in"}
        minibttnlink2 = {"product-details?service=websites&plan=sign-in"}

        miniimage={MiniHomepage4}
        mini__bttn1={"Learn More >"}
        mini__bttn2={"Buy >"}
        mini__link={"product-details?service=websites&plan=contact"}
        mini__link2 = {"product-details?service=websites&plan=contact"}
      />
      <Languages/>
      <Trusted />
      <Footer />
    </div>
  )
}

export default Welcome