import React from 'react';
import './scroll.css'
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';


const scrollData = [
    {
        title: 'Web Applications',
        subtitle: 'Perfect for Personal Sites, Startups, Enterprises, Schools & Federal Entities',
        desc: 'CashCat is a live streaming service designed for content providers in gaming, entertainment, music, sports, and other areas.',
        imgUrl: require('../../assets/store-1.png'),
        url: '/store'
    },
    {
        title: 'Mobile Apps & Games',
        subtitle: 'Perfect for Game Developers, Startups, Small Businesses,  Enterprises, & App Companies',
        imgUrl: require('../../assets/store-2.png'),
        url: '/store'
    },
    
    {
        title: 'Software & SaaS',
        subtitle: 'Perfect for Digital Service Providers, Startups, Small Businesses, Enterprises, & Federal Entities',
        imgUrl: require('../../assets/store-3.png'),
        url: '/store'
    },
    {
        title: 'eCommerce & Magento',
        subtitle: 'Perfect for Shopify Sites, Startups, Enterprises, & Distributors',
        imgUrl: require('../../assets/store-4.png'),
        url: '/store'
    },
    {
        title: "Artificial Intelligence",
        subtitle: 'Perfect for Personal Sites, Startups, Small Businesses, Enterprises, Schools & Federal Entities',
        imgUrl: require('../../assets/store-5.png'),
        url: '/store'
    },
    {
        title: "Blockchain & NFT's",
        subtitle: 'Perfect for Projects, App Developers, Startups, Enterprises, & Federal Entities',
        imgUrl: require('../../assets/store-6.png'),
        url: '/store'
    },
]

const Scroll = () => {
  return (
     <div className='scroll'>
        {scrollData.map((item, index) => (
            <Col lg='2' md='4' sm='5' key={index}>
                  <Link to={item.url}>
                  <div className="scroll__card">
                        <div className="scroll__img">
                            <img src={item.imgUrl} alt="" className='w-100 h-300px' />                         
                        </div>

                        <div className="scroll__content">
                            <div className="content__top">
                                <h3>{item.title}</h3>
                                <h6>{item.subtitle}</h6>
                            </div>
                        </div>
                    </div>
                  </Link>
                </Col>
            )
        )
    }
    </div>
  )
}

export default Scroll