import React from 'react';
import './hiw.css';

const Hiw = () => {
  return (
    <div className='hiw__container  align-items-center gap-5'>
        <div className="single__box w-120">
            <div className="hiw__step">1</div>
            <div className="hiw__title">Blueprinting</div>
            <div className="hiw__description">Detailing of the project, requirements & 
            assigning technology</div>
        </div>
        <div className="single__box w-120">
            <div className="hiw__step">2</div>
            <div className="hiw__title">Design & Development</div>
            <div className="hiw__description">Our team of developers
            delve into augmentation and design of product</div>
        </div>
        <div className="single__box w-120">
            <div className="hiw__step">3</div>
            <div className="hiw__title">Deployment</div>
            <div className="hiw__description">Controlled and systematic 
            release of the product.</div>
        </div>
        
        <div className="single__box w-120">
            <div className="hiw__step">4</div>
            <div className="hiw__title">Maintenance & Evolution</div>
            <div className="hiw__description">Further improvement, continuous integration and 
            enhancement and expansion with zero downtime</div>
        </div>
    </div>
  )
}

export default Hiw;