import React, { Component } from 'react'
import LeftDashboard from '../../components/LeftDashboard'
import firebase from '../../Config/Firebase'
import "./index.css"
import { Navbar } from '../../components';
import { Footer } from '../../containers';

export class Orders extends Component {
    constructor() {
        super()
        this.state = {
            allOrders: [],
            loading: true,
        }
    }


    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.database().ref("all_orders").orderByChild("buyer").equalTo(user.uid).on("value", (data) => {
                    let a = data.val()
                    let arr = []
                    if (a) {
                        let objected = Object.entries(a)
                        objected.map((g) => {
                            arr.push(g[1])
                        })
                    }
                    this.setState({ allOrders: arr, loading: false })
                })
            }
        });
    }


    render() {
        return (
            <div className='main-orders-page'>
                <Navbar />
                <div className='container-dashboard-page'>
                    <div className='left-container-dp'>
                        <LeftDashboard onOrders={true} />
                    </div>
                    {this.state.loading ? <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Orders</h4>
                        <div className='container-psdp'>
                            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div> : <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Orders</h4>
                        <div className='container-rcdp'>
                            {this.state.allOrders.length > 0 && this.state.allOrders.map((g) => {
                                if (g.product === "websites") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp webappsimage'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Web Apps</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "mobile_apps") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp mobileappsimage'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Mobile Apps</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "software_saas") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp softwaresaas'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Software & SaaS</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "ecommerce_magento") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp ecommercemagento'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Ecommerce & Magento</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "artifical_intelligence") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp artifical-intelligence'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Artifical Intelligence</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "blockchain_nfts") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp blockhcain-nft'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Blockchain & NFTs</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "virtual_reality") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp virtual-reality'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Virtual Reality</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "wearable_apps") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp wearable-apps'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Wearable Apps</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                                if (g.product === "device_integration") {
                                    return <div className='inner-container-rcdp' key={Math.random()}>
                                        <div className='bg-inner-crcdp device-integration'>

                                        </div>
                                        <div className='content-inner-crcdp'>
                                            <p className='title-bgicrc'>Device Integration</p>
                                            <p className='desc-bgicrc'>Purchased an application</p>
                                            <p className='desc-bgicrc'>Status: <b>{g.payment_status}</b></p>
                                        </div>
                                    </div>
                                }
                            })}
                            {/* <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp webappsimage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Web Apps</p>
                                    <p className='desc-bgicrc'>Produce next-gen solutions to power your life</p>
                                    <p className='desc-bgicrc'>Status: <b>Pending</b></p>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp mobileappsimage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Mobile Apps</p>
                                    <p className='desc-bgicrc'>Speak to an Etheware Specialist</p>
                                    <br />
                                    <p className='desc-bgicrc'>Status: <b>Paid</b></p>
                                </div>
                            </div> */}
                        </div>
                    </div>}
                </div>
                <Footer />
            </div>
        )
    }
}

export default Orders