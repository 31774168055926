import React from 'react';
import Demo from '.././../assets/macbook-demo.png'
import './ripple.css';
function Ripple() {
    return ( 
        <div className="ripple" id="ripple">
            <div className="ripple__content">
                <h1>
                Create Your App in a Matter of Seconds
                </h1>
                <p>We will work with any budget to make your dream a reality. We understand your needs.
                By using cutting-edge tactics and employing experienced Fortune 500 engineers 
                trained in building professional solutions, we have consistently helped businesses 
                reach their goals no matter their stage.</p>
                <h2>Create without trade-offs. Our platform is built for all levels of entrepreneurs and creators.</h2>

                <div className='ripple__btns d-flex align-items-center gap-4'>
                    <button><a href='/start'>Open an Account</a></button>
                </div>
                    
            </div>
            <div className="ripple__image">
                <img src={Demo} alt='demo' loading="lazy"/>
            </div>
        </div>
    )
}

export default Ripple;
