import React from 'react';
import { Link } from 'react-router-dom';
import './left-media.css';


const LeftMedia = ({ top , header, paragraph, text, button, video__link, btn__link }) => {
    return (
        
        <div className='left-media__container'>
            <div className="video__wrapper">
                <video src={video__link} alt='' autoPlay loop muted/>
            </div>
            <div className='left-media__wrapper'>
                <h3>{top}</h3>
                <h1>{header}</h1>
                <p>{paragraph}</p>
                <h5>{text}</h5>
                <Link to={btn__link}>
                    <button>{button}</button>
                </Link>
            </div>
            
        </div>
    );
}

export default LeftMedia;
