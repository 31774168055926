import React from 'react';
import { Boxes, CTA, Navbar } from '../../components';
import LightModule from '../../components/module/light-module';
import { Comp, Execution, Footer, Interactive } from '../../containers';
import Languages from '../../containers/languages/languages';
import Trusted from '../../containers/trusted/trusted';



const NextGen = () => {
  return (
      <div className='home'>   
        <Navbar />
        <LightModule
            lightmoduleimage={require("../../assets/banner.png")}
            lightmodule__alt={"Web App by Etheware"}
            bttnlink1={"/store"}
            smlightmoduleimage={require("../../assets/banner.png")}
            smlightmodule__alt={"Web App by Etheware"}
            smbttnlink1={"/store"}
        />
        <Interactive />
        <Languages />
        
        <Trusted />
        <Boxes />
        <Comp />
        <Execution />
        <CTA />
        <Footer />
      </div>
      
  );
}

export default NextGen;