import React, { Component } from 'react'
import LeftDashboard from '../../components/LeftDashboard'
import "./index.css"
import { Navbar } from '../../components';
import { Footer } from '../../containers';

export class PaymentMethods extends Component {
    render() {
        return (
            <div className='paymentmethods-container'>
                <Navbar />
                <div className='container-dashboard-page'>
                    <div className='left-container-dp'>
                        <LeftDashboard onPaymentMethods={true} />
                    </div>
                    <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Payment Methods</h4>
                        <div className='container-rcdp'>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp  creditcardimage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Get started</p>
                                    <p className='desc-bgicrc'>Set up simple and convenient service</p>
                                    <button>Add card</button>
                                    <p>Feature Coming V.1.0.4</p>
                                    
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp connectbankimage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Get started</p>
                                    <p className='desc-bgicrc'>Connect your bank to begin ordering services</p>
                                    <button>Connect Bank</button>
                                    <p>Feature Coming V.1.0.4</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default PaymentMethods