import React from 'react';
import './light-module.css';

const LightModule = ({lightmoduleimage, lightmodule__alt, bttnlink1, mdlightmoduleimage, mdlightmodule__alt, smlightmoduleimage, smlightmodule__alt}) => {
  return (
    <div className="light__module">
        <div className="light-module__bg">
            <a href={bttnlink1}><img className="light-module__img" src={lightmoduleimage} alt={lightmodule__alt} loading="lazy"/></a>
            <a href={bttnlink1}><img className="md-light-module__img" src={mdlightmoduleimage} alt={mdlightmodule__alt} loading="lazy"/></a>
            <a href={bttnlink1}><img className="sm-light-module__img" src={smlightmoduleimage} alt={smlightmodule__alt} loading="lazy"/></a>
        </div>
    </div>
  )
}

export default LightModule
