import React from 'react'
import { Navbar } from '../../components'
import Spacebar from '../../components/spacebar/spacebar'
import SpacePreorder from '../../components/SpacePreorder/SpacePreorder'
import { Footer } from '../../containers'

const Preorder = () => {
  return (
    <div>
        <Navbar />
        <Spacebar />
        <SpacePreorder />
        <Footer />
    </div>
  )
}

export default Preorder