import React, { useState } from "react";
import { Button, Col } from "reactstrap";
import './spacebar.css';


const Spacebar = () => {
  return (
    <div className="">
        <Col className="spacebar"> 
        <Col className="spacebar__logo">
            <a href="/preorder">
              <h1>Etheware Space</h1>
            </a>
        </Col>
          <Button><a href="/preorder" className="sp-su_btn">Sign Up</a></Button> 
        </Col> 
    </div>
  )
};
export default Spacebar