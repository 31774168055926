import React from 'react';
import { Navbar } from '../../components';
import ContactForm from '../../containers/ConvertForm';
import './start.css';

const Start = () => {
  return (
        <div className="start">
            <Navbar />
            <ContactForm />
        </div>
  )
}

export default Start