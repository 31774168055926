import React from 'react';
import {Footer } from '../../containers';
import Waitlist from '../../components/waitlist/waitlist';
import { Navbar } from '../../components';

const Home = () => {
  return (
      <div className='home'>
        <Navbar />
        <Waitlist /> 
        <Footer />
      </div>
  );
}

export default Home;
