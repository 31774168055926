import React, { Component } from 'react'
import LeftDashboard from '../../components/LeftDashboard'
import firebase from '../../Config/Firebase'
import "./index.css"
import { Navbar } from '../../components';
import { Footer } from '../../containers';

export class DevPanel extends Component {

    constructor() {
        super()
        this.state = {
            emailVerified: false,
            isMenuOpen: false,
            loading: true,
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ emailVerified: user.emailVerified, loading: false })
            }
        });
    }

    render() {
        return (
            <div className='paymentmethods-container'>
                <Navbar />

                <div className='container-dashboard-page'>
                    <div className='left-container-dp'>
                        <LeftDashboard onDevPanel={true} />
                    </div>
                    {this.state.loading ? <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Dev Panel</h4>
                        <div className='container-psdp'>
                            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div> : <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Dev Panel</h4>
                        {!this.state.emailVerified && <label style={{ color: "red" }}>Warning! Please verify your email</label>}
                        <div className='container-rcdp'>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp liveviewimage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Live View</p>
                                    <p className='desc-bgicrc'>Preview your order as it's being built.</p>
                                    <p>Feature Coming V.1.0.4</p>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp devboximage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Dev Box</p>
                                    <p className='desc-bgicrc'>Download your code and files, updated daily.</p>
                                    <p>Feature Coming V.1.0.4</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <Footer />
            </div>
        )
    }
}

export default DevPanel