import React from 'react';
import './trusted.css';
import Person from '../../assets/testimonial.png';
import { Georgia, Citrix, Benz, Goldman, Nasscom } from  './imports';
import { Row } from 'reactstrap';

const Trusted = () => {
  return (
    <div className="trusted">
        <div className="trusted-content">
            <div className="trusted-text">
                <h1>Trusted</h1>
                <h3>Many of the world's most dynamic companies and individuals find Etheware solutions make them more profitable and effective.</h3>
            </div>
            <Row lg="8" md="5" sm="2" className="trusted-stats">
                <div className="stat-1">
                    <h1>250+</h1>
                    <p>qualified engineers</p>
                </div>
                <div className="stat-2">
                    <h1>1.2M</h1>
                    <p>lines of code written</p>
                </div>
                <div className="stat-3">
                    <h1>$8M</h1>
                    <p>Made for Clients</p>
                </div>
                <div className="stat-4">
                    <h1>240+</h1>
                    <p>Happy Clients</p>
                </div>
            </Row>
            <div className="trusted-banner">
                <div className="banner">
                    <img src={Person} alt="" />
                    <div className="banner-text">
                        <p>Etheware is undoubtedly the top-choice application developer for enterprises and solo entrepreneurs. Their attention to detail and quick turnaround times are what sets them apart from others and makes Etheware a league of it's own.</p>
                        <p>Tim Nichols</p>
                        <h2>Blackberry</h2>
                    </div>
                    
                </div>
                <div className="trusted-bttm">
                    <h3>Our tools are used all over the world in some of the best-known companies.</h3>
                    <div className="companies">
                        <div>
                            <img src={Benz} alt='Mercedes-Benz USA' loading="lazy"/>
                        </div> 
                        <div>
                            <img src={Georgia} alt='Toyota USA' loading="lazy"/>
                        </div> 
                        <div>
                            <img src={Citrix} alt='TD-Ameritrade' loading="lazy"/>
                        </div> 
                        <div>
                            <img src={Nasscom} alt='Nasscom' loading="lazy"/>
                        </div>  
                        <div>
                            <img src={Goldman} alt='Goldman-Sachs' loading="lazy"/>
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Trusted