import React from 'react';
import { Contact, Navbar } from '../../components';
import { Footer } from '../../containers';
import './contact.css';


const ContactPage = () => {
  return (
      <div className='home'>   
        <Navbar />
        <div className='contact'>
          <h1>Etheware Support is here to assist you.</h1>
          <h2>Have a question? Tell us more.</h2>
          <h3>24/7 Support</h3>
        </div>
        <Contact />   
        <Footer />
      </div>
      
  );
}

export default ContactPage;