import './textBox.css'
import React from 'react'

const Textbox = ({ title, text, subtitle }) => {
    return (
        <div className='info__container'>
            <div className='info'>
                <h2>{title}</h2>

                <h1>{text}</h1>

                <p>{subtitle}</p>
            </div>
            
        </div>
    )
}

export default Textbox;
