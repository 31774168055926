import React from 'react';
import { Row } from 'reactstrap';
import "./languages.css";

const Languages = () => {
  return (
    <div className="languages">
        <div className="l-content">
            <div className="top-text">
                <h3>However big or small your team is, our products will ensure that it always has a smooth and enjoyable experience when:</h3>
            </div>
            <div className="l-link">
                <h3><a href="/how-it-works">- Building and shipping your code</a></h3>
                
                <h3><a href="/how-it-works">- Planning your work</a></h3>
               
                <h3><a href="/how-it-works">- Collaborating</a></h3>
               
            </div>
            <div className="bottom-text">
                <h3>Any platform or language you want to work with, Etheware will have a development tool for you.</h3>
            </div>
            <Row className="button-rows">
                <button className="button-ts" href="/">JavaScript</button>
                <button className="button-ts" href="/">.NET</button>
                <button className="button-ts" href="/">Java & JVM</button>
                <button className="button-ts" href="/">C++</button>
                <button className="button-ts" href="/">macOS & iOS</button>
                <button className="button-ts" href="/">Python & Django</button>
                <button className="button-ts" href="/">PHP</button>
                <button className="button-ts" href="/"> Ruby & Rails</button>
                <button className="button-ts" href="/">Go</button>
                <button className="button-ts" href="/">Kotlin</button>
                <button className="button-ts" href="/">SQL</button>
            </Row>
            
        </div>
        
    </div>
  )
}

export default Languages