import React, { useState } from "react";
import './navbar.css';
import Logo from './../../assets/logo.svg';
import Anima from './../../assets/animation.png';
import Icon from './../../assets/etheware-icon.png';
import { RiMenuFill, RiCloseLine, RiAccountBoxFill } from 'react-icons/ri';
import { Image } from "react-bootstrap";


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [accountMenu, setAccountMenu] = useState(false);

  const Menu = () => (
    <nav>

      <p><a href="https://espace.etheware.com/">Etheware Space</a></p>
      <p><a href="/create">Create an App</a></p>
      <p><a href="/store">Enterprise Plans</a></p>
      <li><p><a href="/apps-of-the-year">Apps of the Year</a></p></li>
      <li><p><a href="/company">Company</a></p></li>
      <li><p><a href="/sign-in">Account</a></p></li>
      <li><p><a href="/contact">Support</a></p></li>
    </nav>


) 
const AccountMenu = () => (
  <nav>
    <Image className="space-photo" src={Anima}/>
    <p><a href="/start">Open an Account</a></p> 
    <p><a href="/sign-in">Manage Your Account</a></p> 
    <Image src={Logo}/>
  </nav>

) 
  return (
    <div className="navbar"> 
      <div className="navbar__logo">
        <a href="/">
          <img src={Logo} alt="Etheware 2023" loading="lazy"/>
        </a>
      </div>
      <div className="navbar__icon">
        <a href="/">
          <img src={Icon} alt="Etheware Icon 2023" loading="lazy"/>
        </a>
      </div>
      <div className="icon__div"/>
      <div className="navbar__links">
        <div className="navbar__links-container">
          <div className="nav__container">
            <p><a href="https://espace.etheware.com/">Etheware Space</a></p>
            <div className="icon__div"/>
            <p><a href="/create">Create an Application</a></p>
            <div className="icon__div"/>
            <p><a href="/store">Enterprise Plans</a></p>
            <div className="icon__div"/>
            <p><a href="/apps-of-the-year">Apps of the Year</a></p>
            <div className="icon__div"/>
            <p><a href="/company">Company</a></p>
            <div className="icon__div"/>
            <p><a href="/sign-in">Account</a></p>
            <div className="icon__div"/>
            <p><a href="/contact">Support</a></p>
          </div>
        
          {/* Navbar Menu */}
          <div className="navbar__menu">
            {toggleMenu
              ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)}/>
              : <RiMenuFill color="#fff" size={27} onClick={() => setToggleMenu(true)}/>
            }
            {toggleMenu && (
            <div className="nav-menu__container scale-up-center">
                <div className="menu__links">
                  <Menu />
                </div>
              </div>
              )}
            </div>
            <div className="icon__div"/>
        </div>
        
      </div>
      <div className="account__menu">
          {accountMenu
            ? <RiCloseLine color="#fff" size={27} onClick={() => setAccountMenu(false)}/>
            : <RiAccountBoxFill color="#fff" size={20} onClick={() => setAccountMenu(true)}/>
          }
            {accountMenu && (
            <div className="account-menu__container scale-up-center">
              <div className="account__links">
                <AccountMenu />
              </div>
            </div>
           )}
        </div>
    </div> 
  )
};
export default Navbar;