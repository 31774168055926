import React, { Component } from 'react'
import { IoHomeOutline, IoBasketOutline } from "react-icons/io5"
import { GrUnorderedList, GrClose } from "react-icons/gr"
import { BiLogOut, BiChevronDown } from "react-icons/bi"
import firebase from "../Config/Firebase"
import { MdPayment, MdAccountCircle, MdDeveloperMode } from "react-icons/md"
import Swal from 'sweetalert2'
import "./index.css"

export class LeftDashboard extends Component {
    constructor() {
        super()
        this.state = {
            isDesktop: false,
            isMenuOpen: false,
        }
    }

    componentDidMount() {
        if (window.innerWidth > 768) {
            this.setState({ isDesktop: true })
        } else {
            this.setState({ isDesktop: false })
        }

        window.addEventListener("resize", () => {
            if (window.innerWidth > 768) {
                this.setState({ isDesktop: true })
            } else {
                this.setState({ isDesktop: false })
            }
        })
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                if (!window.location.href.includes("/store")) {
                    Swal.fire({
                        title: 'Inactive Session',
                        text: "Your session has expired, login please",
                        icon: 'error',
                    }).then(() => {
                        window.location.href = "/sign-in"
                    })
                }
            }
        });
    }


    render() {
        return (
            this.state.isDesktop ? <div className='left-dashboard-main'>
                <ul>
                    <li className={this.props.onDashboard ? "active" : ""} onClick={() => window.location.href = "/dashboard"}>
                        <div className='round-circle-icon'>
                            <IoHomeOutline />
                        </div>
                        <font>Dashboard</font>
                    </li>
                    <li className={this.props.onProfileSettings ? "active" : ""} onClick={() => window.location.href = "/profile-settings"}>
                        <div className='round-circle-icon'>
                            <MdAccountCircle style={{ fontSize: 18 }} />
                        </div>
                        <font>Profile Settings</font>
                    </li>
                    <li className={this.props.onOrders ? "active" : ""} onClick={() => window.location.href = "/order-history"}>
                        <div className='round-circle-icon'>
                            <GrUnorderedList />
                        </div>
                        <font>Orders</font>
                    </li>
                    <li className={this.props.onPaymentMethods ? "active" : ""} onClick={() => window.location.href = "/payment-methods"}>
                        <div className='round-circle-icon'>
                            <MdPayment />
                        </div>
                        <font>Payment Methods</font>
                    </li>
                    <li className={this.props.onDevPanel ? "active" : ""} onClick={() => window.location.href = "/dev-panel"}>
                        <div className='round-circle-icon'>
                            <MdDeveloperMode />
                        </div>
                        <font>Dev Panel</font>
                    </li>
                    <li className={this.props.onStore ? "active" : ""} onClick={() => window.location.href = "/store"}>
                        <div className='round-circle-icon'>
                            <IoBasketOutline />
                        </div>
                        <font>Store</font>
                    </li>
                    <li onClick={() => {
                        firebase.auth().signOut().then(() => {
                            localStorage.removeItem("userid")
                            window.location.href = "/sign-in"
                        })
                    }}>
                        <div className='round-circle-icon'>
                            <BiLogOut />
                        </div>
                        <font>Sign out</font>
                    </li>
                </ul>
            </div> : <div className='left-dashboard-main'>
                <div className='main-ldmain' onClick={() => {
                    this.setState({ isMenuOpen: true })
                    document.getElementById("root").style.position = "fixed"
                    document.getElementById("root").style.overflowY = "hidden"
                }}>
                    {this.props.onDashboard && <div className='container-holder-mainldm'>
                        <div className='round-circle-icon'>
                            <IoHomeOutline />
                        </div>
                        <label>Dashboard</label>
                        <BiChevronDown className='dropdown-mobile-icon' />
                    </div>}
                    {this.props.onProfileSettings && <div className='container-holder-mainldm'>
                        <div className='round-circle-icon'>
                            <MdAccountCircle style={{ labelSize: 18 }} />
                        </div>
                        <label>Profile Settings</label>
                        <BiChevronDown className='dropdown-mobile-icon' />
                    </div>}
                    {this.props.onOrders && <div className='container-holder-mainldm'>
                        <div className='round-circle-icon'>
                            <GrUnorderedList />
                        </div>
                        <label>Orders</label>
                        <BiChevronDown className='dropdown-mobile-icon' />
                    </div>}
                    {this.props.onPaymentMethods && <div className='container-holder-mainldm'>
                        <div className='round-circle-icon'>
                            <MdPayment />
                        </div>
                        <label>Payment Methods</label>
                        <BiChevronDown className='dropdown-mobile-icon' />
                    </div>}
                    {this.props.onDevPanel && <div className='container-holder-mainldm'>
                        <div className='round-circle-icon'>
                            <MdDeveloperMode />
                        </div>
                        <label>Dev Panel</label>
                        <BiChevronDown className='dropdown-mobile-icon' />
                    </div>}
                    {this.props.onStore && <div className='container-holder-mainldm'>
                        <div className='round-circle-icon'>
                            <IoBasketOutline />
                        </div>
                        <label>Store</label>
                        <BiChevronDown className='dropdown-mobile-icon' />
                    </div>}
                </div>
                <div className='menu-bar-complete' style={{ opacity: this.state.isMenuOpen ? 1 : 0, pointerEvents: this.state.isMenuOpen ? "all" : "none" }}>
                    <GrClose onClick={() => {
                        this.setState({ isMenuOpen: false })
                        document.getElementById("root").style.position = "static"
                        document.getElementById("root").style.overflowY = "auto"
                    }} className="close-grbc" />
                    <ul>
                        <li className={this.props.onDashboard ? "active" : ""} onClick={() => window.location.href = "/dashboard"}>
                            <div className='round-circle-icon'>
                                <IoHomeOutline />
                            </div>
                            <font>Dashboard</font>
                        </li>
                        <li className={this.props.onProfileSettings ? "active" : ""} onClick={() => window.location.href = "/profile-settings"}>
                            <div className='round-circle-icon'>
                                <MdAccountCircle style={{ fontSize: 18 }} />
                            </div>
                            <font>Profile Settings</font>
                        </li>
                        <li className={this.props.onOrders ? "active" : ""} onClick={() => window.location.href = "/order-history"}>
                            <div className='round-circle-icon'>
                                <GrUnorderedList />
                            </div>
                            <font>Orders</font>
                        </li>
                        <li className={this.props.onPaymentMethods ? "active" : ""} onClick={() => window.location.href = "/payment-methods"}>
                            <div className='round-circle-icon'>
                                <MdPayment />
                            </div>
                            <font>Payment Methods</font>
                        </li>
                        <li className={this.props.onDevPanel ? "active" : ""} onClick={() => window.location.href = "/dev-panel"}>
                            <div className='round-circle-icon'>
                                <MdDeveloperMode />
                            </div>
                            <font>Dev Panel</font>
                        </li>
                        <li className={this.props.onStore ? "active" : ""} onClick={() => window.location.href = "/store"}>
                            <div className='round-circle-icon'>
                                <IoBasketOutline />
                            </div>
                            <font>Store</font>
                        </li>
                        <li onClick={() => {
                            firebase.auth().signOut().then(() => {
                                localStorage.removeItem("userid")
                                window.location.href = "/sign-in"
                            })
                        }}>
                            <div className='round-circle-icon'>
                                <BiLogOut />
                            </div>
                            <font>Sign out</font>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default LeftDashboard