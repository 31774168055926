import React from 'react';
import './innovations.css';

const frontendSkills = [
    {
        title: "React",
        percentage: '95%'
    },
    {
        title: "JavaScript",
        percentage: '92%'
    },
        {
        title: "Bootstrap",
        percentage: '87%'
    },
    {
        title: "Angular",
        percentage: '82%'
    },
    {
        title: "Next",
        percentage: '80%'
    },
    {
        title: "Vue",
        percentage: '79%'
    },
    {
        title: "JQuery",
        percentage: '75%'
    },
]
    const backendSkills = [
        {
            title: "JavaScript",
            percentage: '85%'
        },
        {
            title: "Python",
            percentage: '95%'
        },
        {
            title: "PHP",
            percentage: '79%'
        }
    ]
    const mobileSkills = [
        {
            title: "IOS",
            percentage: '95%'
        },
        {
            title: "React Native",
            percentage: '81%'
        },
        {
            title: "Android",
            percentage: '79%'
        }
    ]
    const aiSkills = [
        {
            title: "Image Detection",
            percentage: '95%'
        },
        {
            title: "Python",
            percentage: '85%'
        }
    ]
    const blockchainSkills = [
        {
            title: "Ethereum",
            percentage: '90%'
        },
        {
            title: "Hyper-laser",
            percentage: '85%'
        },
        {
            title: "NFT Development",
            percentage: '89%'
        }
    ]

const Skills = () => {
    return (
    <div className='innovations__wrapper d-flex gap-5'>
        <div className="frontend__skill w-50">
            <h3>Frontend Favorites</h3>
            {frontendSkills.map((item, index) => (
                <SkillItem
                    key={index}
                    title={item.title}
                    percentage={item.percentage}
                />

            ))}
        </div>
        <div className="frontend__skill w-50">
            <h3>Blockchain Favorites</h3>
            {blockchainSkills.map((item, index) => (
                <SkillItem
                    key={index}
                    title={item.title}
                    percentage={item.percentage}
                />

            ))}
            <h3>AI Favorites</h3>
            {aiSkills.map((item, index) => (
                <SkillItem
                    key={index}
                    title={item.title}
                    percentage={item.percentage}
                />

            ))}
        </div>
         <div className="backend__skill w-50">
         <h3>Backend Favorites</h3>
            {backendSkills.map((item,index) => (
                    <SkillItem
                        key={index}
                        title={item.title}
                        percentage={item.percentage}
                    />
                ))
            }
            <h3>Mobile Favorites</h3>
            {mobileSkills.map((item,index) => (
                    <SkillItem
                        key={index}
                        title={item.title}
                        percentage={item.percentage}
                    />
                ))
            }
         </div>
    </div>
    );
};
const SkillItem = ({title, percentage}) => {
    return (
    <div className="skill__data mb-3">
        <div className="skill__title d-flex align-items-center justify-content-between">
            <h6>{title}</h6>
            <span>{percentage}</span>
        </div>       

        <div className="skill__bar">
            <span 
                className='skill__bar-percentage' 
                style={{width: `${percentage}`}}
            ></span>
        </div>     
    </div>
    )
};

export default Skills;