import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { CTA, Media, Navbar } from '../../components';
import { Footer } from '../../containers';
import './case-studies.css';

const caseData = [
    {
        title: 'CashCat: Live-streams & Podcasts',
        subtitle: 'Blockchain Based Social Media Platform',
        desc: 'CashCat is a live streaming service designed for content providers in gaming, entertainment, music, sports, and other areas to allows user to bid and donate using crypto.',
        imgUrl: require('../../assets/port1.png'),
        url: '/case-studies/cashcat-streaming'
    },
    {
        title: 'Mercedes-AMG GT Roadster: Concept',
        subtitle: 'E-Commerce Platform for Cars',
        desc: 'The 2022 Mercedes-AMG SL Roadster, a sporty two-door convertible, is introduced in a web - based application. Disclaimer: Application Concept',
        imgUrl: require('../../assets/port2.png'),
        url: '/case-studies/amg-mercedes'
    },

    {
        title: 'Charm Shopping: E-Commerce SaaS',
        subtitle: 'B2B SaaS for Startup',
        desc: "Charm is for consumers to trade, built for the worlds emerging middle class to purchase branded hair products suited for them.",
        imgUrl: require('../../assets/port3.png'),
        url: '/case-studies/charm'
    },
    {
        title: 'VM.CRYSTALWARE: Holographic Telecommunication',
        subtitle: 'The Webpage that Started It All',
        desc: 'VM.Crystalware is a subsidiary of Etheware with a focus on bridging the gap between Artificial Intelligence and holographic technology. ',
        imgUrl: require('../../assets/port4.png'),
        url: '/case-studies/vmcrystalware'
    },
    {
        title: "Aurora Mail",
        subtitle: 'Check Updates, Send & Receive Emails, and more',
        desc: "Find all your all your important information in one place with Aurora Mail, send and receive emails and check status updates, and stock information. Accessible by web browser.",
        imgUrl: require('../../assets/port5.png'),
        url: '/case-studies/aurora-mail'
    },
    {
        title: 'Medical Web-Based Application',
        subtitle: 'Online Medical Web-Based Application with Booking & Admin Panel',
        desc: 'Enjoy the videos and music you love, upload original content, and share it all with friends, family, and the world on CashCat.',
        imgUrl: require('../../assets/port6.png'),
        url: '/case-studies/physical-therapy'
    },
]

const CaseStudies = () => {
    return <section>
        <div className='case-studies'>
            <Navbar />
            <Container>
                <Media
                    topline={"Explore how we think, perform and lead the future."}
                    header={"Our Proudest Achievements"}
                    paragraph={"Learn mor about how we embrace technology, strategy, and design to help Fortune 500 Companies, Startups & Scaleups with Digital Transformation."}
                    
                    lin1={require("../../assets/port4-4.png")}
                    btntxt={"Follow Etheware on this Mission"}
                    btnlink={"/socials"}
                />

                <Row className='case__portfolio'>
                    {caseData.map((item, index) => (
                        <Col lg='4' md='6' sm='6' key={index}>
                            <div className="portfolio__card">
                                <div className="portfolio__img">
                                    <img src={item.imgUrl} alt="Portfolio" className='w-100 h-300px' loading="lazy"/>
                                </div>

                                <div className="portfolio__content">
                                    <div className="content__top">
                                        <h3>{item.title}</h3>
                                        <h6>{item.subtitle}</h6>
                                        <p>{item.desc}</p>
                                        <a href={item.url}>View Details</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <CTA />
            <Footer />
        </div>
    </section>
}

export default CaseStudies