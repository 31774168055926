import React, { Component } from 'react';
import "./index.css";
import Logo from './../../assets/cloud.png'
import Swal from 'sweetalert2'
import firebase from "../../Config/Firebase"
import { Navbar } from '../../components';
import { Footer } from '../../containers';

// Etheware Cloud Login, Linked to all Etheware Services
export class SignIn extends Component {

    constructor() {
        super()
        this.state = {
            email: "",
            emailEmpty: false,
            password: "",
            passwordEmpty: false,
        }
    }


    loginUser() {
        const { email, password } = this.state
        let regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (email && password && password.length >= 6 && regexEmail.test(email)) {
            this.setState({
                emailEmpty: false,
                passwordEmpty: false,
                loading: true,
            })
            firebase.auth().signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    var user = userCredential.user;

                    localStorage.setItem("currentUserId", user.uid)
                    window.location.href = "/dashboard"
                })
                .catch((error) => {
                    var errorMessage = error.message;
                    if (errorMessage.includes("There is no user record corresponding to this identifier")) {
                        Swal.fire({
                            title: 'Try again',
                            text: 'This Etheware account does not exist. The user may have been deleted.',
                            icon: 'error',
                        })
                        this.setState({
                            loading: false,
                        })
                    }
                    // ..
                });
        } else {
            if (email === "" || !regexEmail.test(email)) {
                this.setState({ emailEmpty: true })
            } else {
                this.setState({ emailEmpty: false })
            }
            if (password === "" || password.length < 6) {
                this.setState({ passwordEmpty: true })
            } else {
                this.setState({ passwordEmpty: false })
            }
        }
    }


    render() {
        return (
            <div className='sign-in-bg'>
                <Navbar />
                <div className='container-signup' style={{ maxWidth: 370 }}>
                    <img src={Logo} alt="Etheware" loading="lazy"/>
                    <p>Sign in for a personalized experience.</p>
                    {this.state.loading ? <h3>Signing in</h3> : <h3>Sign in</h3>}
                    {this.state.loading ? <div className='container-form-parent'>
                        <div className='container-psdp'>
                            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div> : <div className='container-form-parent'>
                        <div className='container-forms'>
                            <label style={{ color: this.state.emailEmpty ? "red" : "black" }}>Email Address</label>
                            <input type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                        </div>
                        <div className='container-forms'>
                            <label style={{ color: this.state.passwordEmpty ? "red" : "black" }}>Password</label>
                            <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                        </div>
                        <button className='create-account-button' onClick={this.loginUser.bind(this)}>SIGN IN</button>
                        <div className='separator-main'>
                            <div className='horizontal-line'>

                            </div>
                            <label>OR</label>
                            <div className='horizontal-line'>

                            </div>
                        </div>
                        <button className='sign-in-button' onClick={() => window.location.href = "/start"}>CREATE ACCOUNT</button>
                        <p className="forgot-password text-right">
                           <a href="https://etheware.com/reset/auth/action">Forgot your email or password?</a>
                        </p>
                    </div>
                }
                </div>
                <Footer />
            </div>
        )
    }
}

export default SignIn