import "./SpacePreorder.css"
import React, { useRef, useEffect } from 'react';
import Astronaut from '../../assets/space-icon.png';
import './SpacePreorder.css';
import emailjs from '@emailjs/browser';
import{ init } from '@emailjs/browser';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

init("user_Nv6WEeTowVFjmAASEGfpj");

function SpacePreorder() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_lhyl1pb", "template_ic8nrgo", form.current, "user_Nv6WEeTowVFjmAASEGfpj") 
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log('Failed, refresh your page and try again...', error);
          });
          e.target.reset();
          alert('Thank you for joining Etheware Space, check your email for further instructions. - WIth Love, The Etheware Team');
      };

    return ( 
        <div className="preorder">
            <div className="display__title">
                <h1 className="gradient__text">
                Sign up for Etheware Space
                </h1>
                <p>A new level of technology</p>
                <h2 className='super__title'>Have all your favorite files whenever you want</h2>
                <div className="header-content__input">
                    <form className="header-form" id="email-form" ref={form} onSubmit={sendEmail}>
                        <input type="name" 
                            name="full__name" 
                            placeholder="Full Name" 
                            required
                        />
                        <input type="email" 
                            name="email" 
                            placeholder="Email Address" 
                            required
                        />
                        <button className='wl-su_btn' type="submit" value="Send">Sign Up</button>
                    </form>
                    <p className="">You will receive a link to redeem your beta-tester pass once approved.</p>
                    <p>Connect to the internet like it's nobody's business. Because it isn't. Good for all the basics, like emails, traveling, and streaming.</p>
                </div>
            
            </div>
        </div>
        
       
    )
}

export default SpacePreorder;
