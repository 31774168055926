import React from 'react'
import scvideolink from '../../assets/spacetrailer.mp4'
import "./space-trailer.css"

const SpaceTrailer = () => {
  return (
    <div className='home'>
        <div className='vertical-media__container'>
        
            <div className="vetical-video__wrap">
                <video src={scvideolink} alt='Etheware Space Trailer' autoPlay loop controls="true"/>
            </div>
        </div>
    </div>
  )
}

export default SpaceTrailer