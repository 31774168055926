import React from 'react';
import './productimg.css'
import { Col } from 'reactstrap';

const ProductImg = ({ title, subtitle, imgUrl }) => {
  return <section>
      <Col className='productimg__container'>
        <div className="content">
          <div className="image__container">
            <img src={imgUrl} alt='Etheware Products' loading="lazy"/>
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
          </div>
        </div>
      </Col>
  </section>
}

export default ProductImg;