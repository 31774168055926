import React, { Component } from 'react'
import LeftDashboard from '../../components/LeftDashboard'
import { BiArrowBack } from "react-icons/bi"
import { BsChevronRight } from "react-icons/bs"
import { MdOutlineClose } from "react-icons/md"
import "./index.css"
import { Navbar } from '../../components';
import { Footer } from '../../containers';

export class Store extends Component {
    constructor() {
        super()
        this.state = {
            pickUpModal: false,
        }
    }

    render() {
        return (
            <div className='main-store-page'>
                <Navbar />
                <div className='container-dashboard-page'>
                    <div className='left-container-dp'>
                        <LeftDashboard onStore={true} />
                    </div>
                    <div className='right-container-dp' style={{ paddingTop: window.innerWidth < 768 && this.state.pickUpModal && 50 }}>
                        {this.state.pickUpModal ? <h4 className='heading-to-hidemobile' style={{ display: "flex", alignItems: "center" }}><BiArrowBack style={{ marginRight: 15, fontSize: 24, cursor: "pointer" }} onClick={() => this.setState({ pickUpModal: false, selectedService: "" })} /> Pick a Model</h4> : <h4 className='heading-to-hidemobile'>Store</h4>}
                        {this.state.pickUpModal ? <div className='container-rcdp pickupmodalcontainer'>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp etheware-earth'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>Light, scalable and fast</p>
                                    <p className='title-bgicrc'>Etheware Earth</p>
                                    <p className='desc-bgicrc'>From $7500/mo</p>
                                    <p className='desc-bgicrc'>Annual $90K/yr</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=earth`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "earth", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp etheware-bronze'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>For the daredevils</p>
                                    <p className='title-bgicrc'>Etheware Bronze</p>
                                    <p className='desc-bgicrc'>From $10k/mo</p>
                                    <p className='desc-bgicrc'>Annual $120K/yr</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=bronze`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "bronze", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp etheware-silver'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>Supercharged for pros</p>
                                    <p className='title-bgicrc'>Etheware Silver</p>
                                    <p className='desc-bgicrc'>From $12,500/mo</p>
                                    <p className='desc-bgicrc'>Annual $150K/yr</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=silver`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "silver", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp etheware-gold'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>Powerful, Business Max</p>
                                    <p className='title-bgicrc'>Etheware Gold</p>
                                    <p className='desc-bgicrc'>From $15K/mo</p>
                                    <p className='desc-bgicrc'>Annual $180K/yr</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=gold`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "gold", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp diamond-peak'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>Turbocharged and ultra fast</p>
                                    <p className='title-bgicrc'>Diamond Peak</p>
                                    <p className='desc-bgicrc'>From $25K/mo</p>
                                    <p className='desc-bgicrc'>Annual $300K/yr</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=diamond-peak`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "diamond-peak", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp platinum-unlimited'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>Executive, for the best in the class</p>
                                    <p className='title-bgicrc'>Platinum Unlimited</p>
                                    <p className='desc-bgicrc'>From $30k/mo</p>
                                    <p className='desc-bgicrc'>Annual $600K/yr</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=platinum-unlimited`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "platinum-unlimited", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp magento-plan'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='short-desc-bgicrc'>For the hustlers</p>
                                    <p className='title-bgicrc'>Magento</p>
                                    <p className='desc-bgicrc'>Pay as you go</p>
                                    <p className='desc-bgicrc'>&nbsp;</p>
                                    <div className='model-pick-buttons'>
                                        <button onClick={() => {
                                            window.location.href = `/product-details?service=${this.state.selectedService}&plan=magento`
                                        }}>Buy</button>
                                        <a href="javascript:void(0)" onClick={() => {
                                            this.setState({ selectedPlanDetailed: "magento", showLearnMore: true })
                                        }}>Learn More <BsChevronRight /></a>
                                    </div>
                                </div>
                            </div>
                        </div> : <div className='container-rcdp'>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "websites" })
                                    }}>
                                <div className='bg-inner-crcdp webappsimage'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Websites & Web Applications</p>
                                    <p className='desc-bgicrc'>Advanced websites and applications made at your command</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "mobile_apps" })
                                    }}>
                                <div className='bg-inner-crcdp mobileappsimage'>
                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Mobile Apps</p>
                                    <p className='desc-bgicrc'>Build mobile apps and games for Apple and Android devices</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "software_saas" })
                                    }}>
                                <div className='bg-inner-crcdp softwaresaas'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Software & SaaS</p>
                                    <p className='desc-bgicrc'>Design and scale software & SaaS for your needs.</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "ecommerce_magento" })
                                    }}>
                                <div className='bg-inner-crcdp ecommercemagento'>
                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Ecommerce & Magento</p>
                                    <p className='desc-bgicrc'>Power your operation with our Magento & E-Commerce models.</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "artifical_intelligence" })
                                    }}>
                                <div className='bg-inner-crcdp artifical-intelligence'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Artifical Intelligence</p>
                                    <p className='desc-bgicrc'>Design Artificial Intelligence solutions to advance your operation</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "blockchain_nfts" })
                                    }}>
                                <div className='bg-inner-crcdp blockhcain-nft'>
                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Blockchain & NFTs</p>
                                    <p className='desc-bgicrc'>Design your Blockchain based solution.</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "virtual_reality" })
                                    }}>
                                <div className='bg-inner-crcdp virtual-reality'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Virtual Reality</p>
                                    <p className='desc-bgicrc'>Design digital experiences that scale like no other</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "wearable_apps" })
                                    }}>
                                <div className='bg-inner-crcdp wearable-apps'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Wearable Apps</p>
                                    <p className='desc-bgicrc'>Create applications for wearable devices</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                    
                                </div>
                                </a>
                            </div>
                            <div className='inner-container-rcdp'>
                            <a href='javascript:void(0)' onClick={() => {
                                        window.scrollTo(0, 0)
                                        this.setState({ pickUpModal: true, selectedService: "device_integration" })
                                    }}><div className='bg-inner-crcdp device-integration'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Device Integration</p>
                                    <p className='desc-bgicrc'>Integrate your devices all under a controlled host</p>
                                    <br />
                                    <p className='title-bgicrc'>Purchase Now</p>
                                </div>
                                </a>
                            </div>
                            
                        </div>}
                    </div>
                </div>
                {this.state.showLearnMore && <div className='overlay-show-trade'>
                    <div className='container-ostrade'>
                        <MdOutlineClose onClick={() => this.setState({ showLearnMore: false })} />
                        {this.state.selectedPlanDetailed === "earth" && <>
                            <p>5-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 1-2 projects</p>
                            <p>8GB Database storage for your backend needs</p>
                            <p>256GB SSD Team storage for all projects</p>
                            <p>High definition design with exclusive pre-built Etheware Max solutions (International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc.</p>
                            <p>Cybersecurity Essentials - Web Application Firewall, Vulnerability Screening, and Malware Protection, & Face ID enabled Security</p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                        </>}
                        {/* bronze content */}
                        {this.state.selectedPlanDetailed === "bronze" && <>
                            <p>7-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 3-4 projects</p>
                            <p>16GB Database storage for your backend needs</p>
                            <p>500GB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Advanced Cybersecurity - Advanced Firewall Protection, Vulnerability Screening, Server Monitoring, Malware Protection, & Face ID enabled Security</p>
                            <p>Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                        </>}
                        {/* silver content */}
                        {this.state.selectedPlanDetailed === "silver" && <>
                            <p>8-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 5-6 projects</p>
                            <p>32GB Database storage for your backend needs</p>
                            <p>1TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                        </>}
                        {/* gold content */}
                        {this.state.selectedPlanDetailed === "gold" && <>
                            <p>9-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 7-8 projects</p>
                            <p>32GB Database storage for your backend needs</p>
                            <p>1.5TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                        </>}
                        {/* diamond content */}
                        {this.state.selectedPlanDetailed === "diamond-peak" && <>
                            <p>14-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 9-10 projects</p>
                            <p>64GB Database storage for your backend needs</p>
                            <p>2TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                        </>}
                        {/* platinum content */}
                        {this.state.selectedPlanDetailed === "platinum-unlimited" && <>
                            <p>25-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 10+ projects</p>
                            <p>128GB Database storage for your backend needs</p>
                            <p>5TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                        </>}
                        {/* magento content */}
                        {this.state.selectedPlanDetailed === "magento" && <>
                            <p>Everything in the Business Package</p>
                            <p>Up to 20-Hour Business Requirements Review</p>
                            <p>Magento Enterprise Feature Set</p>
                            <p>Customer Group Setup</p>
                            <p>Tiered Pricing Setup</p>
                            <p>Complete custom Magento design</p>
                            <p>eBay and Amazon integration</p>
                            <p>Fully mobile responsive</p>
                            <p>Advanced features</p>
                            <p>Store category automation</p>
                            <p>With Magento, have access to hundreds of unique features that help you connect with your customers and sell your products at ease.</p>
                        </>}
                    </div>
                </div>}
                <Footer />
            </div>
        )
    }
}

export default Store