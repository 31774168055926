import React from 'react';
import { LeftMedia, Media, Navbar } from '../../components';
import { Footer, Header, Scroll } from '../../containers';
import './enterprise.css';
import Image1 from '../../assets/crypto-demo.png';
import LargeVideo from '../../components/media/largevideo';

const Enterprise = () => {
  return (
      <div className='enterprise'>  
        <Navbar />
        <div className='page'>  
          <div className='space'/>
          <LeftMedia 
              top={"If you're lucky, you will have joined early on to our exclusive Early-Bird Research Team that focuses on Beta Testing new company applications, games, and technology. "}
              header={"Join the Waitlist"}
              paragraph={"Each member will receive their name and Research role accredited on each application's credits page which is public. In addition to an Etheware verified certificate mailed to you, along with vouchers for members only exclusive events."}
              text={"Be some of the first in the world to test the most groundbreaking innovations we've seen in recent history alongside prominent researchers, developers, and founders."}
              button={"Apply Now"}
              btn__link={"/waitlist"}
              video__link={require('../../assets/hologram.mp4')}
          />
          <Scroll />
            <Media 
              topline={"We Make the Impossible, Possible"}
              header={"Powerful Enterprise Solutions"}
              paragraph={"The best innovations take time, so while you wait on the list, we've got enterprise packages for you to begin designing high-ranking professional applications and solutions to suit your enterprise needs."}
              btntxt={"Get Started"}
              btnlink={"/store"}
              lin1 = {Image1}
            />
            <Header />
           
        </div>   
        <Footer />
      </div>
  );
}

export default Enterprise;