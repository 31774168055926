import React from 'react';
import { Navbar } from '../../components';
import { Footer, Ripple } from '../../containers';
import "./create.css";

const Create = () => {
  return (
    <div className='home'>
        <Navbar />
        <Ripple /> 
        <Footer /> 
    </div>
  )
}

export default Create