import React, {useState} from 'react';
import './interactive.css';
import { Container, Row, Col } from 'reactstrap';
import interactiveImg1 from '.././../assets/interactive2.png';
import PE from './pe';
import Innovations from './innovations';
import Hiw from './hiw';

const Interactive = () => {
    const [interactiveFilter, setInteractiveFilter] = useState('INTERACTIVE');

  return <section id="interactive"> 
    <Container>
        <Row>
            <Col lg="12" className='mb-5'>
                <h1>How Etheware Makes It Happen</h1> 
            </Col>
            <Col>
                <div className='interactive__buttons d-flex flex-column align-items-center'>
                <button className='interactive_button interactive_button-active' onClick={()=>setInteractiveFilter('INTERACTIVE')}>Next-Gen Innovations</button>
                    <button className='interactive_button interactive_button-active' onClick={()=>setInteractiveFilter('PE')}>Partner Certifications</button>

                    
                    <button className='interactive_button' onClick={()=>setInteractiveFilter('INNOVATIONS')}>Our Engineering Stack</button>
                    <button className='interactive_button' onClick={()=>setInteractiveFilter('HIW')}>How It Works</button>  
                </div>
            </Col>
            <Col lg='8' md="12">
                 {
                    interactiveFilter === "INTERACTIVE" && (
                    <div className='interactive__content__wrapper d-flex gap-5 mt-0 flex-column'>
                        <div className='interactive__img w-20'>
                            <img src={interactiveImg1} alt='Interactive' className='w-110' loading="lazy"/>
                        </div>
                        <div className='interactive__content w-75'>
                            <h2>The possibilities are endless.</h2>
                            <p>Etheware's Alignment research focuses on building helpful Web, 
                                Blockchain, and AI systems to be productive, scalable, and 
                                innovative for enterprises. Our team is 
                                composed of experts in every degree, exploring and developing methods 
                                to learn and improve our product engineering and app development.
                            </p>
                        </div>
                    </div> 
                    )}
                 {
                    interactiveFilter === "PE" &&  <PE />
                 }

                 {
                    interactiveFilter === "INNOVATIONS" && <Innovations />
                 }
                 {
                    interactiveFilter === "HIW" && <Hiw />
                 }
            </Col>
        </Row>
    </Container>
  </section>
}

export default Interactive
