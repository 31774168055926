import React from 'react';
import './portfolio.css'
import { Col, Container, Row } from 'reactstrap';


const portfolioData = [
    {
        title: 'CashCat: Live-streams & Podcasts',
        subtitle: 'Blockchain Based Social Media Platform',
        desc: 'CashCat is a live streaming service designed for content providers in gaming, entertainment, music, sports, and other areas.',
        imgUrl: require('../../assets/port1.png'),
        url: '/case-studies/cashcat-streaming'
    },
    {
        title: 'Mercedes-AMG GT Roadster: Concept',
        subtitle: 'E-Commerce Platform for Cars',
        desc: 'The 2022 Mercedes-AMG SL Roadster, a sporty two-door convertible, is introduced in a web - based application. Disclaimer: Application Concept',
        imgUrl: require('../../assets/port2.png'),
        url: '/case-studies/amg-mercedes'
    },
    
    {
        title: 'Charm Shopping: E-Commerce SaaS',
        subtitle: 'B2B SaaS for Startup',
        desc: "Charm is for consumers to trade, built for the worlds emerging middle class to purchase branded hair products suited for them.",
        imgUrl: require('../../assets/port3.png'),
        url: '/case-studies/charm'
    },
    {
        title: 'VM.CRYSTALWARE: Holographic Telecommunication',
        subtitle: 'The Webpage that Started It All',
        desc: 'VM.Crystalware is a subsidiary of Etheware with a focus on bridging the gap between Artificial Intelligence and holographic technology. ',
        imgUrl: require('../../assets/port4.png'),
        url: '/case-studies/vmcrystalware'
    },
    {
        title: "Aurora Mail",
        subtitle: 'Check Updates, Send & Receive Emails, and more',
        desc: "Find all your all your important information in one place with Aurora Mail, send and receive emails and check status updates, and stock information. Accessible by web browser.",
        imgUrl: require('../../assets/port5.png'),
        url: '/case-studies/teegardens-star'
    },
    {
        title: 'Medical Web-Based Application',
        subtitle: 'Online Medical Web-Based Application with Booking & Admin Panel',
        desc: 'Enjoy the videos and music you love, upload original content, and share it all with friends, family, and the world on CashCat.',
        imgUrl: require('../../assets/port6.png'),
        url: '/case-studies/physical-therapy'
    },
]

const Portfolio = () => {
  return <section>
    <Container>
        <Row className='portfolio'>
            <Col lg='12' className="portfolio__top mb-5">
                <h1>This Month's Project Highlights</h1>
                <h2>Made By Etheware</h2>
            </Col>

            {portfolioData.map((item, index) => (
                <Col lg='4' md='6' sm='6' key={index}>
                    <div className="portfolio__card">
                        <div className="portfolio__img">
                            <img src={item.imgUrl} alt="Etheware Portolio" loading="lazy" className='w-100 h-300px' />
                        </div>

                        <div className="portfolio__content">
                            <div className="pcontent__top">
                                <h3>{item.title}</h3>
                                <h6>{item.subtitle}</h6>
                                <p>{item.desc}</p>
                                <a href={item.url}>View Details</a>
                            </div>
                        </div>
                    </div>
                </Col>
                )
            )
        }
        </Row>
    </Container>
  </section>
}

export default Portfolio