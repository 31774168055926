import React, { Component } from 'react'

import earthImage from "../../assets/etheware-earth-main.jpg"
import earthImageSecond from "../../assets/etheware-earth-main2.jpg"
import earthImageThird from "../../assets/etheware-earth-main3.jpg"
import earthImageFourth from "../../assets/etheware-earth-main4.jpg"

import bronzeImage from "../../assets/etheware-bronze-main.jpeg"
import bronzeImageSecond from "../../assets/etheware-bronze-main2.jpeg"
import bronzeImageThird from "../../assets/etheware-bronze-main3.jpeg"
import bronzeImageFourth from "../../assets/etheware-bronze-main4.jpeg"
import bronzeImageFifth from "../../assets/etheware-bronze-main5.jpeg"
import bronzeImageSixth from "../../assets/etheware-bronze-main6.jpeg"

import silverImage from "../../assets/etheware-silver-main.jpg"
import silverImageSecond from "../../assets/etheware-silver-main2.jpg"
import silverImageThird from "../../assets/etheware-silver-main3.jpg"
import silverImageFourth from "../../assets/etheware-silver-main4.jpg"
import silverImageFifth from "../../assets/etheware-silver-main5.jpg"

import goldImage from "../../assets/etheware-gold-main.jpg"
import goldImageSecond from "../../assets/etheware-gold-main2.jpg"
import goldImageThird from "../../assets/etheware-gold-main3.jpg"
import goldImageFourth from "../../assets/etheware-gold-main4.jpg"
import goldImageFifth from "../../assets/etheware-gold-main5.jpg"

import diamondImage from "../../assets/etheware-diamond-main.jpg"
import diamondImageSecond from "../../assets/etheware-diamond-main2.jpg"
import diamondImageThird from "../../assets/etheware-diamond-main3.jpg"
import diamondImageFourth from "../../assets/etheware-diamond-main4.jpg"
import diamondImageFifth from "../../assets/etheware-diamond-main5.jpg"
import diamondImageSixth from "../../assets/etheware-diamond-main6.jpg"

import platinumImage from "../../assets/etheware-platinum-main.jpg"
import platinumImageSecond from "../../assets/etheware-platinum-main2.jpg"
import platinumImageThird from "../../assets/etheware-platinum-main3.jpg"
import platinumImageFourth from "../../assets/etheware-platinum-main4.jpg"
import platinumImageFifth from "../../assets/etheware-platinum-main5.jpg"
import platinumImageSixth from "../../assets/etheware-platinum-main6.jpg"

import magentoImage from "../../assets/etheware-magento-main.jpg"
import magentoImageSecond from "../../assets/etheware-magento-main2.jpg"
import magentoImageThird from "../../assets/etheware-magento-main3.jpg"
import magentoImageFourth from "../../assets/etheware-magento-main4.jpg"
import magentoImageFifth from "../../assets/etheware-magento-main5.jpg"

import { BsChevronRight } from "react-icons/bs"
import { BiChat, BiChevronDown } from "react-icons/bi"
import { Carousel } from 'react-bootstrap'
import { GrClose, GrCube } from "react-icons/gr"
import { VscDebugStepBack } from "react-icons/vsc"
import { IoBasketOutline } from "react-icons/io5"

import { MdOutlineClose } from "react-icons/md"

import firebase from '../../Config/Firebase'
import { Navbar } from '../../components';
import { Footer } from '../../containers';
import "./index.css"

export class ProductDetails extends Component {

    constructor() {
        super()
        this.state = {
            isSliderOpen: false,
            allEarthImages: [
                earthImageSecond,
                earthImageThird,
                earthImageFourth,
                earthImage,
            ],
            allBronzeImages: [
                bronzeImageSecond,
                bronzeImageThird,
                bronzeImageFourth,
                bronzeImage,
                bronzeImageFifth,
                bronzeImageSixth,
            ],
            allSilverImages: [
                silverImageSecond,
                silverImageThird,
                silverImageFourth,
                silverImage,
                silverImageFifth,
            ],
            allGoldImages: [
                goldImageSecond,
                goldImageThird,
                goldImageFourth,
                goldImage,
                goldImageFifth,
            ],
            allDiamondImages: [
                diamondImageSecond,
                diamondImageThird,
                diamondImageFourth,
                diamondImage,
                diamondImageFifth,
                diamondImageSixth,
            ],
            allPlatinumImages: [
                platinumImageSecond,
                platinumImageThird,
                platinumImageFourth,
                platinumImage,
                platinumImageFifth,
                platinumImageSixth,
            ],
            allMagentoImages: [
                magentoImageSecond,
                magentoImageThird,
                magentoImageFourth,
                magentoImage,
                magentoImageFifth,
            ],
            selectedPlan: "",
            storageSelected: "",
            securitySelected: false,
            marketingSelected: false,
            applicationExist: false,
            selectedModel: "",
            selectedBuyProduct: "",
            user_id: "",
            loading: true,
            yearlyCharge: 0,
            yearlyChargeToAdd: 0,
            monthlyCharge: 0,
            monthlyChargeToAdd: 0,
            paymentOfMonthly: true,
            showTradeInPopup: false,
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user_id: user.uid })
            }
            this.setState({ loading: false })
        });

        document.getElementsByTagName("body")[0].style.backgroundColor = "white"

        let getParams = new URL(window.location.href)

        let checkPlan = getParams.searchParams.get("plan")
        let service = getParams.searchParams.get("service")
        if (checkPlan) {

            if (checkPlan === "earth") {
                this.setState({ yearlyCharge: 90000, yearlyChargeToAdd: 90000, monthlyChargeToAdd: 7500, monthlyCharge: 7500 })
            } else if (checkPlan === "bronze") {
                this.setState({ yearlyCharge: 120000, yearlyChargeToAdd: 120000, monthlyChargeToAdd: 10000, monthlyCharge: 10000 })
            } else if (checkPlan === "silver") {
                this.setState({ yearlyCharge: 150000, yearlyChargeToAdd: 150000, monthlyChargeToAdd: 12500, monthlyCharge: 12500 })
            } else if (checkPlan === "gold") {
                this.setState({ yearlyCharge: 180000, yearlyChargeToAdd: 180000, monthlyChargeToAdd: 15000, monthlyCharge: 15000 })
            } else if (checkPlan === "diamond-peak") {
                this.setState({ yearlyCharge: 300000, yearlyChargeToAdd: 300000, monthlyChargeToAdd: 25000, monthlyCharge: 25000 })
            } else if (checkPlan === "platinum-unlimited") {
                this.setState({ yearlyCharge: 600000, yearlyChargeToAdd: 600000, monthlyChargeToAdd: 30000, monthlyCharge: 30000 })
            } else if (checkPlan === "magento") {
                this.setState({ yearlyCharge: 150000, yearlyChargeToAdd: 150000, monthlyChargeToAdd: 12500, monthlyCharge: 12500 })
            }

            this.setState({ selectedPlan: checkPlan, selectedBuyProduct: service })
        }
    }

    buyProduct() {
        const { selectedPlan, user_id, selectedBuyProduct, storageSelected, securitySelected, marketingSelected, applicationExist, selectedModel } = this.state
        let imageURL = ""
        let productTitle = ""
        let productAmount = 0
        if (this.state.paymentOfMonthly) {
            productAmount = this.state.monthlyCharge * 100
        } else {
            productAmount = this.state.yearlyCharge * 100
        }
        let planSelected = ""
        let developersAmount = ""

        let obj = {
            product: selectedBuyProduct,
            tier: selectedPlan,
            securitySelected,
            marketingSelected,
            applicationExist,
            payment_status: "unpaid",
            productAmount,
        }
        if (user_id) {
            obj.buyer = user_id
        }
        if (storageSelected) {
            obj.additionalStorage = storageSelected
        }
        if (applicationExist) {
            obj.modelExistsOn = selectedModel
        }
        let pushKey = firebase.database().ref("all_orders").push().key
        obj.pushKey = pushKey

        if (selectedBuyProduct === "websites") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/web-apps.jpg?alt=media&token=ebf95b83-3f1b-4193-884e-83c55c2924f2"
            productTitle = "Web Apps"
        } else if (selectedBuyProduct === "mobile_apps") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/mobile-apps.jpg?alt=media&token=e5ff6acf-35c2-4662-9258-93fe3db8443e"
            productTitle = "Mobile Apps"
        } else if (selectedBuyProduct === "software_saas") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/software-saas.jpg?alt=media&token=2faf9e0f-7eae-4114-bc8f-a9150c09d556"
            productTitle = "Software & SaaS"
        } else if (selectedBuyProduct === "ecommerce_magento") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/ecommerce-magento.jpg?alt=media&token=4b4a1ca2-f750-44d3-bcb5-073bc05163a5"
            productTitle = "Ecommerce & Magento"
        } else if (selectedBuyProduct === "artifical_intelligence") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/artifical-intelligence.jpg?alt=media&token=b2c42ca8-3a8b-4159-808a-7c3a4fd87bc9"
            productTitle = "Artifical Intelligence"
        } else if (selectedBuyProduct === "blockchain_nfts") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/blockchain-nft.jpg?alt=media&token=15fc1718-fc37-4916-9719-77458d6f81df"
            productTitle = "Blockchain & NFTs"
        } else if (selectedBuyProduct === "virtual_reality") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/virtual-reality.jpg?alt=media&token=0a54ab19-901e-4cb0-86ba-ed7963864f9b"
            productTitle = "Virtual Reality"
        } else if (selectedBuyProduct === "wearable_apps") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/wearable-apps.jpg?alt=media&token=84e2fae5-3b95-4f6e-8f87-97c0451599e5"
            productTitle = "Wearable Apps"
        } else if (selectedBuyProduct === "device_integration") {
            // imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/device-integration.jpg?alt=media&token=02f1c405-1d78-42bf-878f-e9aaa801f768"
            productTitle = "Device Integration"
        }

        if (selectedPlan === "earth") {
            planSelected = "Etheware Earth"
            developersAmount = "1-2 Teams"
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-earth-stripe.jpg?alt=media&token=392d71e6-d862-44ef-8f44-9c3cfb789cbf"
        } else if (selectedPlan === "bronze") {
            planSelected = "Etheware Bronze"
            developersAmount = "25 Developers"
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-bronze-stripe.jpg?alt=media&token=04f197cb-a9ff-44d6-8f94-d48cc982ea6a"
        } else if (selectedPlan === "silver") {
            planSelected = "Etheware Silver"
            developersAmount = "25 Developers"
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-silver-stripe.jpg?alt=media&token=389de3d5-4882-406a-a618-c2f278bd5994"
        } else if (selectedPlan === "gold") {
            planSelected = "Etheware Gold"
            developersAmount = "10 Developers"
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-gold-stripe.jpg?alt=media&token=b27e29d0-dac5-46bf-9901-b89c5e3046c5"
        } else if (selectedPlan === "diamond-peak") {
            planSelected = "Diamond Peak"
            developersAmount = "10 Developers"
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-dimaond-peak-stripe.jpg?alt=media&token=43d129b0-77cf-4584-b9d3-d2435cd4cdd8"
        } else if (selectedPlan === "platinum-unlimited") {
            planSelected = "Platinum Unlimited"
            developersAmount = "10 Developers"
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-platinum-unlimited-stripe.jpg?alt=media&token=54bb3784-b4f0-4478-8410-aed87016858e"
        } else if (selectedPlan === "magento") {
            planSelected = "Magento"
            developersAmount = "10 Developers"
            productAmount = 10000
            imageURL = "https://firebasestorage.googleapis.com/v0/b/etheware-live.appspot.com/o/etheware-full-integration-stripe.jpg?alt=media&token=822c05d8-0fb7-4191-b598-90f8d867135f"
        }

        let url = `${window.location.origin}/order-history`

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            productTitle: planSelected, developersAmount, imageURL, productAmount, successURL: url, cancelURL: url
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://us-central1-etheware-live.cloudfunctions.net/stripeCheckout", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === 201) {
                    console.log(result)
                    obj.stripeId = result.data.id
                    firebase.database().ref("all_orders/" + pushKey).set(obj)

                    window.location.href = result.data.url
                }
            })
            .catch(error => console.log('error', error));

        // let objNew = {
        //     productTitle: selectedBuyProduct, planSelected: selectedPlan, imageURL, productAmount, successURL: url, cancelURL: url
        // }
        // console.log(objNew, '/yeh hai sab')

        // var stripeCheckout = firebase.functions().httpsCallable('stripeCheckout');
        // stripeCheckout({ productTitle: selectedBuyProduct, planSelected: selectedPlan, imageURL, productAmount, successURL: url, cancelURL: url }).then((response) => {
        //     console.log(response.data, '/this worked')
        //     firebase.database().ref("all_orders/" + pushKey).set(obj)
        // });

    }

    render() {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return (
            <div className='footer-on-detailedpage'>
                <Navbar />
                {this.state.loading ? <div className='right-container-dp'>
                    <div className='container-psdp'>
                        <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                </div> : this.state.isSliderOpen ? <div className='view-gallery-slider'>
                    <GrClose onClick={() => this.setState({ isSliderOpen: false })} className="close-view-gallery" />
                    {this.state.selectedPlan === "earth" && <Carousel interval={null}>
                        {this.state.allEarthImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="Earth"
                                    loading="lazy"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                    {this.state.selectedPlan === "bronze" && <Carousel interval={null}>
                        {this.state.allBronzeImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="Bronze"
                                    loading="lazy"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                    {this.state.selectedPlan === "silver" && <Carousel interval={null}>
                        {this.state.allSilverImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="Silver"
                                    loading="lazy"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                    {this.state.selectedPlan === "gold" && <Carousel interval={null}>
                        {this.state.allGoldImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="Gold"
                                    loading="lazy"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                    {this.state.selectedPlan === "diamond-peak" && <Carousel interval={null}>
                        {this.state.allDiamondImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="Diamond Peak"
                                    loading="lazy"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                    {this.state.selectedPlan === "platinum-unlimited" && <Carousel interval={null}>
                        {this.state.allPlatinumImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="Platinum Unlimited"
                                    loading="lazy"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                    {this.state.selectedPlan === "magento" && <Carousel interval={null}>
                        {this.state.allMagentoImages.map((url) => {
                            return <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={url}
                                    alt="First slide"

                                />
                            </Carousel.Item>
                        })}
                    </Carousel>}
                </div> : <div className='product-details-wrapper'>
                    <div className='left-pdw-main'>
                        {/* earth image main */}
                        {this.state.selectedPlan === "earth" && <img src={earthImage} alt="earth" loading="lazy"/>}
                        {/* bronze image main */}
                        {this.state.selectedPlan === "bronze" && <img src={bronzeImage} alt="bronze" loading="lazy"/>}
                        {/* silver image main */}
                        {this.state.selectedPlan === "silver" && <img src={silverImage} alt="silver" loading="lazy"/>}
                        {/* gold image main */}
                        {this.state.selectedPlan === "gold" && <img src={goldImage} alt="gold" loading="lazy"/>}
                        {/* diamond image main */}
                        {this.state.selectedPlan === "diamond-peak" && <img src={diamondImage} alt="diamond-peak" loading="lazy"/>}
                        {/* diamond image main */}
                        {this.state.selectedPlan === "platinum-unlimited" && <img src={platinumImage} alt="platinum-unlimited" loading="lazy"/>}
                        {/* magento image main */}
                        {this.state.selectedPlan === "magento" && <img src={magentoImage} alt="magento" loading="lazy"/>}

                        <p className='view-gallery-link' onClick={() => this.setState({ isSliderOpen: true })}>View Gallery</p>
                        <div className='tabs-info-detailed'>
                            <div className='inner-tidetailed'>
                                <GrCube />
                                <p>Instant order confirmation and service as soon as you order</p>
                            </div>
                            <div className='inner-tidetailed'>
                                <VscDebugStepBack />
                                <p>Free and easy returns</p>
                            </div>
                        </div>
                        <div className='chat-info-detailed'>
                            <BiChat />
                            <div>
                                <p>Have questions about picking a plan?</p>
                                <p>
                                    <a href="tel:619-872-3038" style={{ color: "white" }}>
                                        Chat with a Etheware Specialist
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='right-pdw-main'>
                        {/* earth content */}
                        {this.state.selectedPlan === "earth" && <>
                            <h3>ETHEWARE EARTH</h3>
                            <p>5-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 1-2 projects</p>
                            <p>8GB Database storage for your backend needs</p>
                            <p>256GB SSD Team storage for all projects</p>
                            <p>High definition design with exclusive pre-built Etheware Max solutions (International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc.</p>
                            <p>Cybersecurity Essentials - Web Application Firewall, Vulnerability Screening, and Malware Protection, & Face ID enabled Security</p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Earth Plan <BsChevronRight /></p>
                        </>}
                        {/* bronze content */}
                        {this.state.selectedPlan === "bronze" && <>
                            <h3>ETHEWARE BRONZE</h3>
                            <p>7-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 3-4 projects</p>
                            <p>16GB Database storage for your backend needs</p>
                            <p>500GB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Advanced Cybersecurity - Advanced Firewall Protection, Vulnerability Screening, Server Monitoring, Malware Protection, & Face ID enabled Security</p>
                            <p>Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Bronze Plan <BsChevronRight /></p>
                        </>}
                        {/* silver content */}
                        {this.state.selectedPlan === "silver" && <>
                            <h3>ETHEWARE SILVER</h3>
                            <p>8-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 5-6 projects</p>
                            <p>32GB Database storage for your backend needs</p>
                            <p>1TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Silver Plan <BsChevronRight /></p>
                        </>}
                        {/* gold content */}
                        {this.state.selectedPlan === "gold" && <>
                            <h3>ETHEWARE GOLD</h3>
                            <p>9-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 7-8 projects</p>
                            <p>32GB Database storage for your backend needs</p>
                            <p>1.5TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Gold Plan <BsChevronRight /></p>
                        </>}
                        {/* diamond content */}
                        {this.state.selectedPlan === "diamond-peak" && <>
                            <h3>ETHEWARE DIAMOND PEAK</h3>
                            <p>14-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 9-10 projects</p>
                            <p>64GB Database storage for your backend needs</p>
                            <p>2TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Diamond Peak Plan <BsChevronRight /></p>
                        </>}
                        {/* platinum content */}
                        {this.state.selectedPlan === "platinum-unlimited" && <>
                            <h3>ETHEWARE PLATINUM UNLIMITED</h3>
                            <p>25-Etheware Pro Engineers in a certified team at your command</p>
                            <p>Project Capacity: 10+ projects</p>
                            <p>128GB Database storage for your backend needs</p>
                            <p>5TB SSD Team storage for all projects</p>
                            <p>Ultra-High definition designs with exclusive custom made Etheware Max graphic designs and exclusive pre-built solutions</p>
                            <p>International Language Support on all Platforms- including with English, Afrikaans, Spanish, Chinese, and etc</p>
                            <p>Enterprise Cybersecurity - Professional 24/7 Monitoring, Dual Advanced Firewall Protection, Next-Gen Vulnerability Screening, Server Protection, Malware Protection, & Face ID enabled Security</p>
                            <p>Advanced Etheware Marketing Tools </p>
                            <p>Zero Downtime (24/7/365)- have your solutions live as we work</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Platinum Unlimited Plan <BsChevronRight /></p>
                        </>}
                        {/* magento content */}
                        {this.state.selectedPlan === "magento" && <>
                            <h3>ETHEWARE MAGENTO</h3>
                            <p>Everything in the Business Package</p>
                            <p>Up to 20-Hour Business Requirements Review</p>
                            <p>Magento Enterprise Feature Set</p>
                            <p>Customer Group Setup</p>
                            <p>Tiered Pricing Setup</p>
                            <p>Complete custom Magento design</p>
                            <p>eBay and Amazon integration</p>
                            <p>Fully mobile responsive</p>
                            <p>Advanced features</p>
                            <p>Store category automation</p>
                            <p>With Magento, have access to hundreds of unique features that help you connect with your customers and sell your products at ease.</p>
                            <p className='anchor-right-main'>Learn more about the Etheware Magento Plan <BsChevronRight /></p>
                        </>}
                        <div className='etheware-trade-in'>
                            <p>Etheware Trade In</p>
                            <p>
                                Get credit toward a new model when you trade in your eligible application
                                <br />
                                for credit. Or keep it free.
                            </p>
                            <a href="#trade-in-question">Get started</a>
                        </div>
                        {this.state.selectedPlan === "earth" && <div className='perks-products-main'>
                            <div className='inner-ppm'>
                                <h4>5 Devs</h4>
                                <p>Developers<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>1-2/projects</h4>
                                <p>Project load<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>2x/ms</h4>
                                <p>Speed</p>
                            </div>
                        </div>}
                        {this.state.selectedPlan === "bronze" && <div className='perks-products-main'>
                            <div className='inner-ppm'>
                                <h4>7 Devs</h4>
                                <p>Developers<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>3-4/projects</h4>
                                <p>Project load<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>2x/ms</h4>
                                <p>Speed</p>
                            </div>
                        </div>}
                        {this.state.selectedPlan === "silver" && <div className='perks-products-main'>
                            <div className='inner-ppm'>
                                <h4>8 Devs</h4>
                                <p>Developers<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>5-6/projects</h4>
                                <p>Project load<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>2x/ms</h4>
                                <p>Speed</p>
                            </div>
                        </div>}
                        {this.state.selectedPlan === "gold" && <div className='perks-products-main'>
                            <div className='inner-ppm'>
                                <h4>9 Devs</h4>
                                <p>Developers<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>7-8/projects</h4>
                                <p>Project load<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>2x/ms</h4>
                                <p>Speed</p>
                            </div>
                        </div>}
                        {this.state.selectedPlan === "diamond-peak" && <div className='perks-products-main'>
                            <div className='inner-ppm'>
                                <h4>14 Devs</h4>
                                <p>Developers<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>9-10/projects</h4>
                                <p>Project load<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>2x/ms</h4>
                                <p>Speed</p>
                            </div>
                        </div>}
                        {this.state.selectedPlan === "platinum-unlimited" && <div className='perks-products-main'>
                            <div className='inner-ppm'>
                                <h4>25 Devs</h4>
                                <p>Developers<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>10+ projects</h4>
                                <p>Project load<br />(est.)</p>
                            </div>
                            <div className='inner-ppm'>
                                <h4>2x/ms</h4>
                                <p>Speed</p>
                            </div>
                        </div>}
                        {this.state.selectedPlan === "magento" && <div className='perks-products-main'>
                            <div className='inner-ppm' style={{ width: "100%", textAlign: "left" }}>
                                <h4>Customizable</h4>
                            </div>
                        </div>}
                        <div className='product-details-more'>
                            <div className='inner-pdm-main'>
                                {this.state.selectedPlan === "earth" && <h4>Etheware Earth</h4>}
                                {this.state.selectedPlan === "bronze" && <h4>Etheware Bronze</h4>}
                                {this.state.selectedPlan === "silver" && <h4>Etheware Silver</h4>}
                                {this.state.selectedPlan === "gold" && <h4>Etheware Gold</h4>}
                                {this.state.selectedPlan === "diamond-peak" && <h4>Diamond Peak</h4>}
                                {this.state.selectedPlan === "platinum-unlimited" && <h4>Platinum Unlimited</h4>}

                                {this.state.selectedPlan === "earth" && <div className={`round-inner-pdm ${this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: true })}>
                                    <p>Earth Model Monthly</p>
                                    <p>$7,500</p>
                                </div>}
                                {this.state.selectedPlan === "earth" && <div className={`round-inner-pdm ${!this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: false })}>
                                    <p>Earth Model Yearly</p>
                                    <p>$90,000</p>
                                </div>}
                                {this.state.selectedPlan === "bronze" && <div className={`round-inner-pdm ${this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: true })}>
                                    <p>Bronze Model Monthly</p>
                                    <p>$10,000</p>
                                </div>}
                                {this.state.selectedPlan === "bronze" && <div className={`round-inner-pdm ${!this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: false })}>
                                    <p>Bronze Model Yearly</p>
                                    <p>$120,000</p>
                                </div>}
                                {this.state.selectedPlan === "silver" && <div className={`round-inner-pdm ${this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: true })}>
                                    <p>Silver Model Monthly</p>
                                    <p>$12,500</p>
                                </div>}
                                {this.state.selectedPlan === "silver" && <div className={`round-inner-pdm ${!this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: false })}>
                                    <p>Silver Model Yearly</p>
                                    <p>$150,000</p>
                                </div>}
                                {this.state.selectedPlan === "gold" && <div className={`round-inner-pdm ${this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: true })}>
                                    <p>Gold Model Monthly</p>
                                    <p>$15,000</p>
                                </div>}
                                {this.state.selectedPlan === "gold" && <div className={`round-inner-pdm ${!this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: false })}>
                                    <p>Gold Model Yearly</p>
                                    <p>$180,000</p>
                                </div>}
                                {this.state.selectedPlan === "diamond-peak" && <div className={`round-inner-pdm ${this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: true })}>
                                    <p>Diamond Peak Monthly</p>
                                    <p>$25,000</p>
                                </div>}
                                {this.state.selectedPlan === "diamond-peak" && <div className={`round-inner-pdm ${!this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: false })}>
                                    <p>Diamond Peak Yearly</p>
                                    <p>$300,000</p>
                                </div>}
                                {this.state.selectedPlan === "platinum-unlimited" && <div className={`round-inner-pdm ${this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: true })}>
                                    <p>Platnium Unlimited Monthly</p>
                                    <p>$50,000</p>
                                </div>}
                                {this.state.selectedPlan === "platinum-unlimited" && <div className={`round-inner-pdm ${!this.state.paymentOfMonthly && "active"}`} onClick={() => this.setState({ paymentOfMonthly: false })}>
                                    <p>Platnium Unlimited Yearly</p>
                                    <p>$600,000</p>
                                </div>}
                                <p><u>Learn more</u> about Speed and Project Load</p>
                            </div>
                            {this.state.selectedPlan !== "magento" && <div className='inner-pdm-main'>
                                <h4>SSD</h4>
                                {this.state.selectedPlan === "earth" && <div className={`round-inner-pdm ${this.state.storageSelected === "500GB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        // if (this.state.storageSelected === "1TB") {
                                        //     let addedCharge = this.state.yearlyCharge - 7500
                                        //     let toSet = addedCharge + 2500
                                        //     let addedChargeMonthly = this.state.monthlyCharge - 7500
                                        //     let toSetMonthly = addedChargeMonthly + 2500
                                        //     this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                        // }
                                        let toSet = this.state.yearlyCharge - 2500
                                        let toSetMonthly = this.state.monthlyCharge - 2500
                                        this.setState({ storageSelected: "", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    } else {
                                        let toSet = this.state.yearlyCharge + 2500
                                        let toSetMonthly = this.state.monthlyCharge + 2500
                                        this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>500GB</p>
                                    <p>$2,500</p>
                                </div>}
                                {this.state.selectedPlan === "bronze" && <div className={`round-inner-pdm ${this.state.storageSelected === "500GB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        let toSet = this.state.yearlyCharge - 3000
                                        let toSetMonthly = this.state.monthlyCharge - 3000
                                        this.setState({ storageSelected: "", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    } else {
                                        let toSet = this.state.yearlyCharge + 3000
                                        let toSetMonthly = this.state.monthlyCharge + 3000
                                        this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>1TB</p>
                                    <p>$3,000</p>
                                </div>}
                                {this.state.selectedPlan === "silver" && <div className={`round-inner-pdm ${this.state.storageSelected === "500GB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        let toSet = this.state.yearlyCharge - 3500
                                        let toSetMonthly = this.state.monthlyCharge - 3500
                                        this.setState({ storageSelected: "", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    } else {
                                        let toSet = this.state.yearlyCharge + 3500
                                        let toSetMonthly = this.state.monthlyCharge + 3500
                                        this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>2TB</p>
                                    <p>$3,500</p>
                                </div>}
                                {this.state.selectedPlan === "gold" && <div className={`round-inner-pdm ${this.state.storageSelected === "500GB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        let toSet = this.state.yearlyCharge - 4000
                                        let toSetMonthly = this.state.monthlyCharge - 4000
                                        this.setState({ storageSelected: "", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    } else {
                                        let toSet = this.state.yearlyCharge + 4000
                                        let toSetMonthly = this.state.monthlyCharge + 4000
                                        this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>3TB</p>
                                    <p>$4,000</p>
                                </div>}
                                {this.state.selectedPlan === "diamond-peak" && <div className={`round-inner-pdm ${this.state.storageSelected === "500GB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        let toSet = this.state.yearlyCharge - 4500
                                        let toSetMonthly = this.state.monthlyCharge - 4500
                                        this.setState({ storageSelected: "", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    } else {
                                        let toSet = this.state.yearlyCharge + 4500
                                        let toSetMonthly = this.state.monthlyCharge + 4500
                                        this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>4TB</p>
                                    <p>$4,500</p>
                                </div>}
                                {this.state.selectedPlan === "platinum-unlimited" && <div className={`round-inner-pdm ${this.state.storageSelected === "500GB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        let toSet = this.state.yearlyCharge - 5000
                                        let toSetMonthly = this.state.monthlyCharge - 5000
                                        this.setState({ storageSelected: "", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    } else {
                                        let toSet = this.state.yearlyCharge + 5000
                                        let toSetMonthly = this.state.monthlyCharge + 5000
                                        this.setState({ storageSelected: "500GB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>5TB</p>
                                    <p>$5,000</p>
                                </div>}
                                {/* <div className={`round-inner-pdm ${this.state.storageSelected === "1TB" && "active"}`} onClick={() => {
                                    if (this.state.storageSelected) {
                                        if (this.state.storageSelected === "500GB") {
                                            let addedCharge = this.state.yearlyCharge - 2500
                                            let toSet = addedCharge + 7500
                                            let addedChargeMonthly = this.state.monthlyCharge - 2500
                                            let toSetMonthly = addedChargeMonthly + 7500
                                            this.setState({ storageSelected: "1TB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                        }
                                    } else {
                                        let toSet = this.state.yearlyCharge + 7500
                                        let toSetMonthly = this.state.monthlyCharge + 7500
                                        this.setState({ storageSelected: "1TB", yearlyCharge: toSet, monthlyCharge: toSetMonthly })
                                    }
                                }}>
                                    <p>1TB</p>
                                    <p>$97,500</p>
                                </div> */}
                                <p>Prices shown without the $750 Etheware Trade-in Reward and potential incentives, referrals and savings for a total of $3,750.</p>
                            </div>}
                        </div>
                        <div className='pre-installed-worker'>
                            <h4>Pre-Installed Software</h4>
                            {this.state.selectedPlan !== "magento" && <div className='inner-piwk'>
                                <p>Security</p>
                                <p>Learn more</p>
                                <div className='selection-ipiwk'>
                                    <div className={`inner-spiwk ${!this.state.securitySelected && "active"}`} onClick={() => {
                                        if (this.state.securitySelected) {
                                            let addedCharge = this.state.monthlyCharge - 799.99
                                            let addedChargeYearly = this.state.yearlyCharge - 799.99
                                            this.setState({ monthlyCharge: addedCharge, yearlyCharge: addedChargeYearly })
                                        }
                                        this.setState({ securitySelected: false })
                                    }}>
                                        <p>None</p>
                                    </div>
                                    <div className={`inner-spiwk ${this.state.securitySelected && "active"}`} onClick={() => {
                                        if (!this.state.securitySelected) {
                                            let addedCharge = this.state.monthlyCharge + 799.99
                                            let addedChargeYearly = this.state.yearlyCharge + 799.99
                                            this.setState({ monthlyCharge: addedCharge, yearlyCharge: addedChargeYearly })
                                        }
                                        this.setState({ securitySelected: true })
                                    }}>
                                        <p>Full Security</p>
                                        <p>+ $799.99</p>
                                    </div>
                                </div>
                            </div>}
                            {this.state.selectedPlan !== "magento" && <div className='inner-piwk'>
                                <p>Marketing</p>
                                <p>Learn more</p>
                                <div className='selection-ipiwk'>
                                    {/* <div className={`inner-spiwk ${!this.state.marketingSelected && "active"}`} onClick={() => this.setState({ marketingSelected: false })}>
                                        <p>None</p>
                                    </div>
                                    <div className={`inner-spiwk ${this.state.marketingSelected && "active"}`} onClick={() => this.setState({ marketingSelected: true })}>
                                        <p>Digital Creatives</p>
                                        <p>+ $3,750.00</p>
                                    </div> */}
                                    <div className={`inner-spiwk ${!this.state.marketingSelected && "active"}`} onClick={() => {
                                        if (this.state.marketingSelected) {
                                            let addedCharge = this.state.monthlyCharge - 3750
                                            let addedChargeYearly = this.state.yearlyCharge - 3750
                                            this.setState({ monthlyCharge: addedCharge, yearlyCharge: addedChargeYearly })
                                        }
                                        this.setState({ marketingSelected: false })
                                    }}>
                                        <p>None</p>
                                    </div>
                                    <div className={`inner-spiwk ${this.state.marketingSelected && "active"}`} onClick={() => {
                                        if (!this.state.marketingSelected) {
                                            let addedCharge = this.state.monthlyCharge + 3750
                                            let addedChargeYearly = this.state.yearlyCharge + 3750
                                            this.setState({ monthlyCharge: addedCharge, yearlyCharge: addedChargeYearly })
                                        }
                                        this.setState({ marketingSelected: true })
                                    }}>
                                        <p>Digital Marketing</p>
                                        <p>+ $3,750.00</p>
                                    </div>
                                </div>
                            </div>}
                            <div className='inner-piwk' id="trade-in-question">
                                <p>Do you have an application or website to trade in with Etheware?</p>
                                <p onClick={() => this.setState({ showTradeInPopup: true })}>See how trade-in works</p>
                                <div className='selection-ipiwk'>
                                    <div className={`inner-spiwk ${!this.state.applicationExist && "active"}`} onClick={() => this.setState({ applicationExist: false })}>
                                        <p>No</p>
                                    </div>
                                    <div className={`inner-spiwk ${this.state.applicationExist && "active"}`} onClick={() => this.setState({ applicationExist: true })}>
                                        <p>Yes</p>
                                    </div>
                                </div>
                            </div>
                            {this.state.applicationExist && <div className='inner-provider-main'>
                                <p>What provider do you have?</p>
                                <p>Whether it's built being using a Template or from scratch, trade in for cashback.</p>
                                <br />
                                <p>If your application is part of the Etheware upgrade program</p>
                                <p className="bluecolortext">check your upgrade elegibility for trade in <BsChevronRight /></p>
                                <p style={{ marginTop: 15, color: "black" }}>Choose your model</p>
                                <div className='select-position'>
                                    <select value={this.state.selectedModel} onChange={(e) => {
                                        this.setState({ selectedModel: e.target.value })
                                    }}>
                                        <option value="shopify">Shopify</option>
                                        <option value="wordpress">Wordpress</option>
                                        <option value="wix">Wix</option>
                                        <option value="goDaddy">GoDaddy</option>
                                        <option value="squarespace">Squarespace</option>
                                        <option value="developer_ade">Developer Made</option>
                                    </select>
                                    <BiChevronDown />
                                </div>
                            </div>}
                        </div>
                        {this.state.selectedPlan === "magento" ? <div className='bottom-buy-rpdm'>
                            <button>
                                <a href="tel:619-872-3038" style={{ color: "white" }}>
                                    Contact Etheware
                                </a>
                            </button>
                        </div> : <div className='bottom-buy-rpdm'>
                            <button onClick={this.buyProduct.bind(this)}>Continue to checkout</button>
                        </div>}
                    </div>
                    <div className="sticky-footer">
                        <div className='footer-container'>
                            <div className='advanced-footer-main'>
                                <IoBasketOutline />
                                <div>
                                    <p>Order now.</p>
                                    <p style={{ color: "black" }}>Start designing online</p>
                                </div>
                            </div>
                            <div className='advanced-footer-main'>
                                <GrCube />
                                <div>
                                    <p>2-Year Warranty</p>
                                    <p>Etheware Warranty</p>
                                </div>
                            </div>
                            {this.state.selectedPlan === "magento" ? <div className='pricing-footer-main'>
                                <button>
                                    <a href="tel:619-872-3038" style={{ color: "white" }}>
                                        Contact Etheware
                                    </a>
                                </button>
                            </div> : <div className='pricing-footer-main'>
                                {/* {this.state.selectedPlan === "earth" && <div>
                                    <p>$90,000.00 or</p>
                                    <p>$7,500.00 for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>}
                                {this.state.selectedPlan === "bronze" && <div>
                                    <p>$120,000.00 or</p>
                                    <p>$10,000.00 for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>}
                                {this.state.selectedPlan === "silver" && <div>
                                    <p>{formatter.format(this.state.yearlyCharge)} or</p>
                                    <p>{formatter.format(this.state.monthlyCharge)} for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>}
                                {this.state.selectedPlan === "gold" && <div>
                                    <p>$180,000.00 or</p>
                                    <p>$15,000.00 for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>}
                                {this.state.selectedPlan === "diamond-peak" && <div>
                                    <p>$300,000.00 or</p>
                                    <p>$25,000.00 for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>}
                                {this.state.selectedPlan === "platinum-unlimited" && <div>
                                    <p>$600,000.00 or</p>
                                    <p>$30,000.00 for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>} */}
                                <div>
                                    <p>{formatter.format(this.state.yearlyCharge)} or</p>
                                    <p>{formatter.format(this.state.monthlyCharge)} for 12 mo.*</p>
                                    <p className='blue-inner-pfm'>Get annual rewards with Etheware card</p>
                                </div>
                                <button onClick={this.buyProduct.bind(this)}>Continue</button>
                            </div>}
                        </div>
                    </div>
                </div>}
                {this.state.showTradeInPopup && <div className='overlay-show-trade'>
                    <div className='container-ostrade'>
                        <MdOutlineClose onClick={() => this.setState({ showTradeInPopup: false })} />
                        <p>Applications built by a developer or templated using providers such as Shopify, Wordpress, Wix, Squarespace, or GoDaddy are eligible for trade-in up to $850.</p>
                    </div>
                </div>}
                <Footer />
            </div>
        )
    }
}

export default ProductDetails