import React from 'react';
import './legal.css';
import { Navbar } from '../../components';
import { Footer } from '../../containers';

const Legal = () => {
  return (
    <div className='legal'>
        <Navbar />
        <div className="legal__banner">
        </div>
            <div className="text">
                <h1>Legal</h1>
                <p>Review the terms of your agreement with Etheware in the use of our products, software, and services. </p>
                <h3>Privacy Policy</h3>
                <p>Get to know our privacy practices, how we collect and process data, and your choices about how information is used, 
                    in a format that is easy to read and navigate.
                </p>
                <h6><a href='/legal/privacy-policy'>Read Privacy Policy</a></h6>

                <h3>Terms of Service</h3>
                <p>Get to know our privacy practices, how we collect and process data, and your choices about how information is used, 
                    in a format that is easy to read and navigate.
                </p>
                <h6><a href='/legal/terms-of-service'>Read Terms of Service</a></h6>

               

                {/* <h3>Ethics and Compliance</h3>
                <p>Etheware conducts business ethically, honestly, and in full compliance with the law.</p>
                <h6><a href='/legal/ethics-and-compliance'>Read Ethics and Compliance</a></h6>

                <h3>Sales & Support</h3>
                <p>Details about the policies, terms, and conditions for the purchase, support, and servicing of your Etheware products.</p>
                <h6><a href='/legal/sales-and-support'>Read Sales & Support</a></h6>

                <h3>Intellectual Property</h3>
                <p>Etheware's innovation is embodied in its Intellectual Property, including Patents, Trademarks, and Copyrights. Learn about our policies regarding intellectual property.</p>
                <h6><a href='/legal/intellectual-property'>Explore Intellectual Property</a></h6> */}

            </div>
        <Footer />
    </div>
  )
}

export default Legal