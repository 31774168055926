import React from 'react';
import './header.css'
import AI from '../../assets/ai.png';
import './header.css';
import { Col } from 'reactstrap';

function Header() {
    
    return ( 
        <Col className="header">
            <div className="header__content">
                <h1 className="gradient__text">
                    Watch as history is being made
                </h1>
                <p>Etheware is the spirit of never accepting defeat up even in the face of the 'impossible'. This is why each and every release from Etheware, will break a barrier to humanity previously unheard of. </p>
                <h2>To witness the behind the scenes of this mission, learn about upcoming releases, win giveaways, and preview applications, Follow us on all platforms and join the Etheware Community</h2>
                <div className="header-content__input">
                <a href='https://www.twitter.com/ethewaretech'><button className='wl-su_btn'>Twitter</button></a>
                <a href='https://www.instagram.com/ethewaretech'><button className='wl-su_btn'>Instagram</button></a>
                <a href='https://www.linkedin.com/ethewaretech'><button className='wl-su_btn'>Linkedin</button></a>
                <a href='https://www.youtube.com/channel/UCpV8SAxy3dKwS58WMFZd3Tg'><button className='wl-su_btn'>Youtube</button></a>
            </div>

            </div>
            <div className="header__image">
                <img src={AI} alt='Planet Etheware' loading="lazy"/>
            </div>
        </Col>
    )
}

export default Header;
