import React, { Component } from 'react'
import "./index.css";
import { Navbar } from '../../components';
import Logo from './../../assets/etheware.png'
import { Footer } from '../../containers';
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import firebase from "../../Config/Firebase";
import Swal from 'sweetalert2';

// Etheware Cloud Signup, Linked to all Etheware Services
export class ContactForm extends Component {

    constructor() {
        super()
        this.state = {
            fullName: "",
            fullNameEmpty: false,
            market: "US",
            marketEmpty: false,
            language: "English",
            languageEmpty: false,
            tel: "",
            telEmpty: false,
            email: "",
            emailEmpty: false,
            password: "",
            passwordEmpty: false,
        }
    }


    createAccount() {
        const { market, language, fullName, tel, email, password } = this.state
        let regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (market && language && fullName && tel && email && password && password.length >= 6 && regexEmail.test(email)) {
            this.setState({
                fullNameEmpty: false,
                marketEmpty: false,
                languageEmpty: false,
                telEmpty: false,
                emailEmpty: false,
                passwordEmpty: false,
                loading: true,
            })
            let obj = {
                market,
                language,
                fullName: fullName,
                tel: tel,
                email,
                is_verified: false,
            }
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    var user = userCredential.user;
                    obj.user_id = user.uid
                    firebase.auth().currentUser.sendEmailVerification()
                        .then(() => {
                            firebase.database().ref("users/" + user.uid).set(obj)
                            Swal.fire({
                                title: 'Welcome!',
                                text: 'An email verification has been sent to your account, verify it to access your Etheware Cloud features',
                                icon: 'success',
                            }).then(() => {
                                this.setState({
                                    fullName: "",
                                    market: "US",
                                    language: "English",
                                    tel: "",
                                    email: "",
                                    password: "",
                                    loading: false,
                                })
                                localStorage.setItem("currentUserId", user.uid)
                                window.location.href = "/dashboard"
                            })
                        });
                })
                .catch((error) => {
                    var errorMessage = error.message;
                    Swal.fire({
                        title: 'Oops!',
                        text: errorMessage,
                        icon: 'error',
                    })
                    this.setState({
                        loading: false,
                    })
                    // ..
                });
        } else {
            if (market) {
                this.setState({ marketEmpty: false })
            } else {
                this.setState({ marketEmpty: true })
            }
            if (language) {
                this.setState({ languageEmpty: false })
            } else {
                this.setState({ languageEmpty: true })
            }
            if (fullName) {
                this.setState({ fullNameEmpty: false })
            } else {
                this.setState({ fullNameEmpty: true })
            }
            if (tel) {
                this.setState({ telEmpty: false })
            } else {
                this.setState({ telEmpty: true })
            }
            if (market === "" || !regexEmail.test(market)) {
                this.setState({ marketEmpty: true })
            } else {
                this.setState({ marketEmpty: false })
            }
            if (language === "" || !regexEmail.test(language)) {
                this.setState({ languageEmpty: true })
            } else {
                this.setState({ languageEmpty: false })
            }
            if (fullName === "" || !regexEmail.test(fullName)) {
                this.setState({ fullNameEmpty: true })
            } else {
                this.setState({ fullNameEmpty: false })
            }
            if (tel === "" || !regexEmail.test(tel)) {
                this.setState({ telEmpty: true })
            } else {
                this.setState({ telEmpty: false })
            }
            if (email === "" || !regexEmail.test(email)) {
                this.setState({ emailEmpty: true })
            } else {
                this.setState({ emailEmpty: false })
            }
            if (password === "" || password.length < 6) {
                this.setState({ passwordEmpty: true })
            } else {
                this.setState({ passwordEmpty: false })
            }
        }
    }

    showPassword() {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }


    render() {
        return (
            <div className="lead">
                <Navbar />
                <div className='leadform'>
                    <h3>Open an Account</h3>
                    {this.state.loading ? <div className='lead-form-parent'>
                        <div className='lead-psdp'>
                            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div> : <div className='lead-form-parent'>
                        <div className='convert-forms'>
                            <label style={{ color: this.state.marketEmpty ? "black" : "black" }}>Select Market</label>
                            
                            <div className='lead-boxes-holder'>
                                <select value={this.state.marketEmpty} onChange={(e) => {
                                    this.setState({ marketEmpty: e.target.value })
                                }}>
                                    <option value="US">United States</option>
                                    <option value="UK">UK</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="IN">India</option>
                                    <option value="EU">Europe</option>
                                </select>
                                <BiChevronDown />
                            </div>
                        </div>
                        <div className='convert-forms'>
                            <label style={{ color: this.state.languageEmpty ? "red" : "black" }}>Select Language
                                <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        Choose your preferred language
                                    </Tooltip>
                                }
                            >
                                <i className='tooltip-icon'></i>
                            </OverlayTrigger>
                            </label>
                            <div className='lead-boxes-holder'>
                                <select value={this.state.language} onChange={(e) => {
                                    this.setState({ language: e.target.value })
                                }}>
                                    <option value="English">English</option>
                                    <option value="French">French</option>
                                    <option value="Italian">Italian</option>
                                </select>
                                <BiChevronDown />
                            </div>
                        </div>
                        <div className='convert-forms'>
                            <label style={{ color: this.state.firstNameEmpty ? "red" : "black" }}>Full Name</label>
                            
                            <input placeholder='Full Name' type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                        </div>
                        <div className='convert-forms'>
                            <label style={{ color: this.state.lastNameEmpty ? "red" : "black" }}>Contact Number</label>
                            <input placeholder="(000)-000-0000" type="tel" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                        </div>
                        <div className='convert-forms'>
                            <label style={{ color: this.state.emailEmpty ? "red" : "black" }}>Email Address</label>
                            <input placeholder="appleseed@etheware.com" type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                        </div>
                        <div className='convert-forms'>
                            <label style={{ color: this.state.passwordEmpty ? "red" : "black" }}>Password
                            <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        Please enter a strong password (5 Characters or more)
                                    </Tooltip>
                                }
                            >
                                <i className='tooltip-icon'></i>
                            </OverlayTrigger>
                            </label>
                            <input placeholder="Enter a strong password" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                        </div>
                        <a href="#" onClick={() => window.location.href = "/thank-you"}><button className='open-account-button' onClick={this.createAccount.bind(this)}>CREATE ACCOUNT</button></a>
                        <div className='separator-main'>
                            <div className='horizontal-line'>

                            </div>
                            <label>OR</label>
                            <div className='horizontal-line'/>
                        </div>
                        <button className='login-button' onClick={() => window.location.href = "/sign-in"}>SIGN IN</button>

                    </div>}
                    <div className="notice">
                        <p>We understand the trust you place in us and our responsibility to protect your privacy. To learn about how we will handle your privacy, read our privacy policy.</p> 
                        <a href="/legal">Etheware Privacy Statement</a>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default ContactForm