import React from 'react';
import { Contact, CTA, LeftMedia, Media, Navbar } from '../../components';
import { Footer, Interactive } from '../../containers';

const Port5 = () => {
  return (
    <div className='home'>
        <Navbar />
       
        <LeftMedia 
          topline={"Have all your information in one secure place"}
          header={"Business Overview"}
          paragraph={"At the core, Aurora Mail is a mail provider and information provider. Use Aurora Mail to send & receive messages to individuals, groups, or your entire organization. New team members automatically get added—no need to share phone numbers, circulate emails, or update group chats."}
          text={"Aurora Mail wanted to make it easy for anyone to use our service and be fully informed whenever, where ever."}
          videolink={require('../../assets/port5-1.mp4')}
          button={"Have Questions?"}
          buttonlink={'/contact'}
        />
        <Media 
          topline={"The Launch Into Space"}
          header={"The Challenge"}
          paragraph={"One of the major concerns in creating this application was ensuring the security against attacks and breaches. In order to solve this, we enabled Two-factor authentication (2FA), DDOS protection, and unrecognized activity warnings to ensure your feed will only be viewable by you. The first feature we implemented faced here was the time-consuming development of this platform's custom personalized feed."}
          lin1={require('../../assets/port5.png')}
        />
        <LeftMedia 
          topline={"The Orbit"}
          header={"The Solution"}
          paragraph={"After using MERN (MongoDB, Express, ReactJS, & NodeJS), The Aurora Mail solution was created. It employs a unique protocol for the mail and finance sector. The protocol combines APIs that fetch news, stock information, and reminders all in one handy place. "}
          text={"You can now send & receive emails, check reminders, stocks, news and, all your important information in one place."}
          videolink={require('../../assets/port5-2.mp4')}
          button={"Create an App Now"}
          buttonlink={'/contact'}
        />
        <Media 
          topline={"Aurora Mail: The Home Base"}
          header={"Advantages of Having an Account"}
          paragraph={"Huge storage space, Online anywhere access, Cheaper and less manpower (only you!) to maintain, Synchronization with Outlook, Store Instant Messages (IM) and video conferencing, Easy search and organization, and Security of data."}
          text={"Etheware handled all of the back-end development while collaborating with the frontend experts to mix your Google, Yahoo, or Outlook accounts in one neat place."}
          lin1={require('../../assets/port5-2.png')}
        />
        <Interactive />
        <CTA />
        <Contact />
        <Footer />
    </div>
  )
}

export default Port5