import React from 'react';
import { Contact, CTA, LeftMedia, Media, Navbar, TextBox } from '../../components';
import { Footer, Interactive } from '../../containers';

const Port6 = () => {
  return (
    <div className='home'>
        <Navbar />
        <LeftMedia
            topline={"Medical Web-Based Application"}
            header={"Physical Therapy Website"}
            paragraph={"Our client's major goal is to allow users to exercise their bodies and preserve their physique as a temple through their own schedule, as well as customize their training routines based on their schedule."}
            text={"Want to Build an Application Just Like This?"}
            videolink={require('../../assets/port6-1.mp4')}
            buttonlink={'/contact'}
            button={"Contact Etheware to Begin"}
        />
        <Media 
          topline={"Healthcare and Technology"}
          header={"Business Overview"}
          paragraph={"Our client's headquarters are in San Diego, California. They needed an app that would allow users to track their fitness goals and share their experiences, book physical therapy sessions, rate and evaluate their service, and organize seminars all in one spot."}
          text={"The major focus is creating a personalized fitness training online program that allows anybody and everyone to train whoever, whenever, and wherever they choose. Personalize your fitness objectives and workout habits based on your training habits, schedules, and other factors."}
          lin1={require('../../assets/port6-1.png')}
        />
        <Media 
          topline={"From Concept to Reality"}
          header={"The Challenge"}
          paragraph={"They sought to create a fitness training platform that would allow users to effortlessly connect with the proper and suitable training and achieve their own fitness potential. Overcoming the challenges of meeting client desires, and the reality of the difficulty of linking trainers with users around the clock at all times with trainer availability proved to be a challenge."}
          text={""}
          lin1={require('../../assets/port6-2.png')}
        />
        <LeftMedia
            topline={"Etheware paves the way"}
            header={"The Solution"}
            paragraph={"Our solution was an on-demand fitness training software that allows users to search for and connect with fitness experts nearby. This software is a personalized fitness app that compares your preferences to the expertise of fitness trainers and matches you with the suitable fitness coach. "}
            text={"The application secures and protects your fitness data with excellent encryption and provides dietitians and fitness instructors with the tools they need to coach clients and achieve greater personal fitness achievement."}
            videolink={require('../../assets/port6-3.mp4')}
            buttonlink={'/contact' }
            button={"Create Yours Now"}
        />
        <Media 
          topline={"From Concept to Reality"}
          header={"The Challenge"}
          paragraph={"They sought to create a fitness training platform that would allow users to effortlessly connect with the proper and suitable training and achieve their own fitness potential. Overcoming the challenges of meeting client desires, and the reality of the difficulty of linking trainers with users around the clock at all times with trainer availability proved to be a challenge."}
          text={""}
          lin1={require('../../assets/port6-2.png')}
        />
        <TextBox
          title={"Outcome"}
          text={"Before creating the platform's UI and UX design, Etheware conducted extensive meetings, research and development in order to make the applications concept work. In addition, we created a unique design for the platform."}
          subtitle={"During deployment, Etheware rapidly and effectively corrected any issues that arose with the client and hosting. We successfully launched the project and it now serves as a successful template for healthcare web applications."}
        />
        <Interactive />
        <CTA />
        <Contact />
        <Footer />
    </div>
  )
}

export default Port6