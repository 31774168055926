import { Row } from 'reactstrap';
import "./intro.css";
import Websites from './../../assets/etheware-icon1.png';
import MobileApps from './../../assets/etheware-icon2.png';
import Software from './../../assets/etheware-icon3.png';
import Blockchain from './../../assets/etheware-icon4.png';
import React, { useRef, useEffect } from 'react';
import { init } from 'ityped';

const Intro = () => {
    const textRef = useRef()
    useEffect(()=>{
        init(textRef.current, {
            backDelay: 1500,
            showCursor: true,
            strings:[
                'Websites & Web Apps  ', 
                'Mobile Apps  ', 
                'Software & SaaS  ',
                "Blockchain Solutions  ",
                "NFT's and Cryptocurrencies  ",
                'Artificial Intelligence   ',
                'SEO & Marketing   '
            ]
        })
    }, [])
    // const bannerData = [
    //     {
    //         imgUrl: require('./../../assets/intro-1.png'),
    //         url: '/store',
    //         btnext: "Get Started"
    //     },
    //     {
    //         imgUrl: require('./../../assets/intro-3.png'),
    //         url: '/contact',
    //         btnext: "Get Started"
    //     }
    // ]
            return (
                <section className="intro" id="intro">
                    <div className="intro-content">
                        <h3>Etheware helps you make money and build </h3>
                        <h2 className='intro__title'>Powerful <span ref={textRef}></span></h2>
                        <p>We build your custom applications  so you can enjoy living your life to the fullest</p>
                        <Row lg='6' md='3' sm='5' className="wt">
                            <div className="wt-item">
                                <div className="wt-item__text">
                                    <img src={Websites} className="wt-icon" alt="Websites" loading="lazy"/>
                                    <h3 className="wt-h1">Websites</h3>
                                </div>
                                <p className="wt-p">The complete developer toolkit</p>
                                <a class="wt-link" href="/contact">Start Now</a>
                            </div>
                            <div className="wt-item">
                                <div className="wt-item__text">
                                    <img src={MobileApps} className="wt-icon" alt="Websites" loading="lazy"/>
                                    <h3 className="wt-h1">Mobile Apps</h3>
                                </div>
                                <p className="wt-p">The complete developer toolkit</p>
                                <a class="wt-link" href="/contact">Start Now</a>
                            </div>
                            <div className="wt-item">
                                <div className="wt-item__text">
                                    <img src={Software} className="wt-icon" alt="Websites" loading="lazy"/>
                                    <h3 className="wt-h1">Software</h3>
                                </div>
                                <p className="wt-p">The complete developer toolkit</p>
                                <a class="wt-link" href="/contact">Start Now</a>
                            </div>
                            <div className="wt-item">
                                <div className="wt-item__text">
                                    <img src={Blockchain} className="wt-icon" alt="Websites" loading="lazy"/>
                                    <h3 className="wt-h1">Blockchain</h3>
                                </div>
                                <p className="wt-p">The complete developer toolkit</p>
                                <a class="wt-link" href="/contact">Start Now</a>
                            </div>
                        </Row>
                    </div>
                    {/* <div className="tiles">
                        <Row className='banner-tiles'>
                            {bannerData.map((item, index) => (
                                <Col lg='3' md='5' sm='8' key={index}>
                                    <div className="big-tile">
                                        <div className="big-tile__img">
                                            <a href={item.url}><img src={item.imgUrl} alt="Etheware Benefits" className='w-00 h-100px'/></a>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div> */}
                </section>
    
  )
}

export default Intro