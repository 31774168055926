import React from 'react'
import './mini.css'
import { Col } from 'reactstrap';

const Mini = ({minimoduleimage, miniimage, minimodule__alt, mini__alt, mini__link, mini__link2}) => {
  return (
    <div className="mini__module">
      <div className="mini-module__bg">
        <div className="mini__module1">
          <a href={minimoduleimage}><img className="mini-module__img"  alt={minimodule__alt} loading="lazy"/></a>
        </div>
            <Col lg='2' md='3' sm='1' className="mini__module2">
              <div className="mini-module__bg2">
                  <a href={miniimage}><img className="mini-module__img2" alt={mini__alt} loading="lazy"/></a>
              </div>
          </Col>
      </div>
    </div>
  )
}

export default Mini