import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './comp.css';

const Comp = () => {
    const compData = [
        {
            icon: 'ri-discuss-line',
            title: 'Consulting',
            desc: 'Discussing product goals, infrastructure, challenges, and feedback on product optimization and technology upgrade',
        },
        {
            icon: 'ri-pencil-line',
            title: 'Architecture',
            desc: "Detailed analysis to understand product requirements and identify technical infrastructure to integrate with existing systems",
        },
        {
            icon: 'ri-discuss-line',
            title: 'Design & UX',
            desc: 'Product development using Agile development strategy, maintaining transparency throughout and lowering risks',
        },
        {
            icon: 'ri-code-box-line',
            title: 'Development',
            desc: 'Product development using Agile development strategy, maintaining transparency throughout and lowering risks',
        },
        {
            icon: 'ri-settings-2-line',
            title: 'Testing & QA',
            desc: 'Continuous testing of products and services to ensure seamless integration, error-free coding and uninterrupted function',
        },
        {
            icon: 'ri-line-chart-line',
            title: 'DevOps & Evolution',
            desc: 'Product development using Agile development strategy, maintaining transparency throughout and lowering risks',
        }
    ]


  return <section id="comp">
    <Container>
        <Row>
            <Col className="comp__top" lg='12'>
                <h6>Components of Developing a Product</h6>
                <h2>Our Product Engineering Procedure</h2> 
            </Col>
            
            {compData.map((item, index) => (
                <Col lg='4' md='6' sm='6'>
                    <div className="single__comp">
                        <span className="single__icon">
                            <i class={item.icon}></i>
                        </span>
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                    </div>
                </Col>
            ))}
        </Row>
    </Container>
  </section>
}

export default Comp;