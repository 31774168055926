import React, { Component } from 'react'
import LeftDashboard from '../../components/LeftDashboard'
import firebase from '../../Config/Firebase'
import { GrFormAdd } from "react-icons/gr"
import "./index.css"
import Swal from 'sweetalert2'
import { Navbar } from '../../components';
import { Footer } from '../../containers';

export class ProfileSettings extends Component {

    constructor() {
        super()
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            loading: true,
            userid: "",
            phoneNumber: "",
            address: "",
        }
    }

    async componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.database().ref("users/" + user.uid).on("value", (data) => {
                    let a = data.val()
                    if (a.phone_number) {
                        this.setState({ phoneNumber: a.phone_number })
                    }
                    if (a.address) {
                        this.setState({ address: a.address })
                    }
                    this.setState({ firstName: a.first_name, lastName: a.last_name, email: a.email, loading: false, userid: user.uid })
                })
            }
        });
    }

    editPhoneNumber(type) {
        const { userid } = this.state

        Swal.fire({
            title: type === "edit-real" ? 'Edit Number' : 'Add Number',
            html:
                `<label  style="width: 100%; text-align: left; margin-bottom: 5px; color: black;">Number</label>` +
                `<input type="number" id="numberInput" value='${this.state.phoneNumber}' placeholder="Number" style="width: 100%; padding: 8px 12px;" />` +
                `<label id="numberAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Please enter a valid email</label>`,
            showCancelButton: true,
            preConfirm: () => {
                let number = document.getElementById("numberInput").value
                if (!number || number.length !== 10) {
                    if (!number || number.length !== 10) {
                        document.getElementById("numberAlert").style.display = "inline-block"
                    } else {
                        document.getElementById("numberAlert").style.display = "none"
                    }
                    return false;
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let number = document.getElementById("numberInput").value
                firebase.database().ref("users/" + userid + "/phone_number").set(number)
            }
        })
    }

    editPassword() {
        Swal.fire({
            title: 'Edit Password',
            html:
                `<label  style="width: 100%; text-align: left; margin-bottom: 5px; color: black;">Current Password</label>` +
                `<input type="password" id="currentPasswordInput" placeholder="Current Password" style="width: 100%; padding: 8px 12px;" />` +
                `<label id="currentPasswordAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Please enter a valid password</label>` +
                `<label  style="width: 100%; text-align: left; margin-top: 10px; margin-bottom: 5px; color: black;">New Password</label>` +
                `<input type="password" id="newPasswordInput" placeholder="New Password" style="width: 100%; padding: 8px 12px;" />` +
                `<label id="newPasswordAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Please enter a valid password</label>`,
            showCancelButton: true,
            preConfirm: () => {
                let currentPassword = document.getElementById("currentPasswordInput").value
                let newPassword = document.getElementById("newPasswordInput").value
                if (!currentPassword || !newPassword || newPassword.left < 6) {
                    if (currentPassword) {
                        document.getElementById("currentPasswordAlert").style.display = "none"
                    } else {
                        document.getElementById("currentPasswordAlert").style.display = "inline-block"
                    }
                    if (newPassword && newPassword.length > 5) {
                        document.getElementById("newPasswordAlert").style.display = "none"
                    } else {
                        document.getElementById("newPasswordAlert").style.display = "inline-block"
                    }
                    return false;
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // let number = document.getElementById("numberInput").value
                // firebase.database().ref("users/" + userid + "/phone_number").set(number)

                let currentPassword = document.getElementById("currentPasswordInput").value
                let newPassword = document.getElementById("newPasswordInput").value

                const user = firebase.auth().currentUser;

                const credential = firebase.auth.EmailAuthProvider.credential(
                    this.state.email,
                    currentPassword
                );
                // Now you can use that to reauthenticate
                this.setState({ loading: true })
                user.reauthenticateWithCredential(credential).then(() => {
                    user.updatePassword(newPassword).then(() => {
                        Swal.fire({
                            title: 'Cool!',
                            text: 'Password changed successfully.',
                            icon: 'success',
                        }).then(() => {
                            this.setState({ loading: false })

                            localStorage.removeItem("currentUserId")
                            window.location.href = "/sign-in"
                        });
                    }).catch((error) => {
                        var errorMessage = error.message;
                        Swal.fire({
                            title: 'Oops!',
                            text: errorMessage,
                            icon: 'error',
                        }).then(() => {
                            this.setState({ loading: false })
                        })
                    });
                }).catch((error) => {
                    var errorMessage = error.message;
                    Swal.fire({
                        title: 'Oops!',
                        text: errorMessage,
                        icon: 'error',
                    }).then(() => {
                        this.setState({ loading: false })
                    })
                });

            }
        })
    }


    editAddress(type) {
        const { userid } = this.state

        Swal.fire({
            title: type === "edit-real" ? "Add Address" : 'Edit Address',
            html:
                `<label  style="width: 100%; text-align: left; margin-bottom: 5px; color: black;">Address</label>` +
                `<input type="type" id="addressInput" placeholder="Address" value='${this.state.address}' style="width: 100%; padding: 8px 12px;" />` +
                `<label id="addressAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Address required</label>`,
            showCancelButton: true,
            preConfirm: () => {
                let address = document.getElementById("addressInput").value
                if (!address) {
                    if (address) {
                        document.getElementById("addressAlert").style.display = "none"
                    } else {
                        document.getElementById("addressAlert").style.display = "inline-block"
                    }
                    return false;
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let address = document.getElementById("addressInput").value
                firebase.database().ref("users/" + userid + "/address").set(address)
            }
        })
    }


    render() {
        const { userid } = this.state
        return (
            <div>
                <Navbar />
                <div className='container-dashboard-page'>
                    <div className='left-container-dp'>
                        <LeftDashboard onProfileSettings={true} />
                    </div>
                    {this.state.loading ? <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Profile Settings</h4>
                        <div className='container-psdp'>
                            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div> : <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Profile Settings</h4>
                        <div className='container-psdp'>
                            <div className='inner-container-rcdp'>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Full Name</p>
                                    <p className='desc-bgicrc' style={{ fontWeight: 600 }}>{this.state.firstName} {this.state.lastName}</p>
                                    <a href='javascript:void(0)' onClick={() => {
                                        Swal.fire({
                                            title: 'Edit your name',
                                            html:
                                                `<label  style="width: 100%; text-align: left; margin-bottom: 5px; color: black;">First Name</label>` +
                                                `<input type="text" id="firstNameInput" value='${this.state.firstName}' placeholder="First Name" style="width: 100%; padding: 8px 12px;" />` +
                                                `<label id="firstNameAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">First Name required</label>` +
                                                `<label style="width: 100%; text-align: left; margin-top: 10px; margin-bottom: 5px; color: black;">Last Name</label>` +
                                                `<input type="text" id="lastNameInput" value='${this.state.lastName}' placeholder="Last Name" style="width: 100%; padding: 8px 12px;" />` +
                                                `<label id="lastNameAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Last Name required</label>`,
                                            showCancelButton: true,
                                            preConfirm: () => {
                                                let firstName = document.getElementById("firstNameInput").value
                                                let lastName = document.getElementById("lastNameInput").value
                                                if (!lastName || !firstName) {
                                                    if (firstName) {
                                                        document.getElementById("firstNameAlert").style.display = "none"
                                                    } else {
                                                        document.getElementById("firstNameAlert").style.display = "inline-block"
                                                    }
                                                    if (lastName) {
                                                        document.getElementById("lastNameAlert").style.display = "none"
                                                    } else {
                                                        document.getElementById("lastNameAlert").style.display = "inline-block"
                                                    }
                                                    return false;
                                                }
                                            },
                                            allowOutsideClick: () => !Swal.isLoading()
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                let firstName = document.getElementById("firstNameInput").value
                                                let lastName = document.getElementById("lastNameInput").value
                                                firebase.database().ref("users/" + userid + "/first_name").set(firstName)
                                                firebase.database().ref("users/" + userid + "/last_name").set(lastName)
                                            }
                                        })
                                    }}>Edit</a>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                {/* <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Address</p>
                                    <div className='add-new-bgicrc'>
                                        <div className='circle-icon-anbg'>
                                            <GrFormAdd />
                                        </div>
                                        <label>Add New</label>
                                    </div>
                                </div> */}
                                {this.state.address ? <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Address</p>
                                    <p className='desc-bgicrc' style={{ fontWeight: 600 }}>{this.state.address}</p>
                                    <a href='javascript:void(0)' onClick={this.editAddress.bind(this, "edit-real")}>Edit</a>
                                </div> : <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Address</p>
                                    <div className='add-new-bgicrc' onClick={this.editAddress.bind(this)}>
                                        <div className='circle-icon-anbg'>
                                            <GrFormAdd />
                                        </div>
                                        <label>Add New</label>
                                    </div>
                                </div>}
                            </div>
                            <div className='inner-container-rcdp'>
                                {this.state.phoneNumber ? <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Phone Number</p>
                                    <p className='desc-bgicrc' style={{ fontWeight: 600 }}>{this.state.phoneNumber}</p>
                                    <a href='javascript:void(0)' onClick={this.editPhoneNumber.bind(this, "edit-real")}>Edit</a>
                                </div> : <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Phone Number</p>
                                    <div className='add-new-bgicrc' onClick={this.editPhoneNumber.bind(this)}>
                                        <div className='circle-icon-anbg'>
                                            <GrFormAdd />
                                        </div>
                                        <label>Add New</label>
                                    </div>
                                </div>}
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Email</p>
                                    <p className='desc-bgicrc' style={{ fontWeight: 600 }}>{this.state.email}</p>
                                    <a href='javascript:void(0)' onClick={() => {
                                        Swal.fire({
                                            title: 'Edit your email',
                                            html:
                                                `<label  style="width: 100%; text-align: left; margin-bottom: 5px; color: black;">Email</label>` +
                                                `<input type="text" id="emailInput" value='${this.state.email}' placeholder="Email" style="width: 100%; padding: 8px 12px;" />` +
                                                `<label id="emailAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Please enter a valid email</label>` +
                                                `<label  style="width: 100%; text-align: left; margin-top: 10px; margin-bottom: 5px; color: black;">Password</label>` +
                                                `<input type="password" id="passwordInput" placeholder="Password" style="width: 100%; padding: 8px 12px;" />` +
                                                `<label id="passwordAlert" style="display: none; color: red; width: 100%; text-align: left; font-size: 13px;">Please enter your password</label>`,
                                            showCancelButton: true,
                                            preConfirm: () => {
                                                let email = document.getElementById("emailInput").value
                                                let password = document.getElementById("passwordInput").value
                                                let regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                if (!email || !password) {
                                                    if (email) {
                                                        document.getElementById("emailAlert").style.display = "none"
                                                    } else {
                                                        document.getElementById("emailAlert").style.display = "inline-block"
                                                    }
                                                    if (password) {
                                                        document.getElementById("passwordAlert").style.display = "none"
                                                    } else {
                                                        document.getElementById("passwordAlert").style.display = "inline-block"
                                                    }
                                                    return false;
                                                } else {
                                                    if (!regexEmail.test(email)) {
                                                        document.getElementById("emailAlert").style.display = "inline-block"
                                                        return false;
                                                    }
                                                }
                                            },
                                            allowOutsideClick: () => !Swal.isLoading()
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                const user = firebase.auth().currentUser;

                                                let email = document.getElementById("emailInput").value
                                                let password = document.getElementById("passwordInput").value

                                                const credential = firebase.auth.EmailAuthProvider.credential(
                                                    this.state.email,
                                                    password
                                                );
                                                // Now you can use that to reauthenticate
                                                this.setState({ loading: true })

                                                user.reauthenticateWithCredential(credential).then(() => {
                                                    user.updateEmail(email).then(() => {
                                                        user.sendEmailVerification()
                                                            .then(() => {
                                                                firebase.database().ref("users/" + userid + "/email").set(email)
                                                                Swal.fire({
                                                                    title: 'Cool!',
                                                                    text: 'Email changed successfully, and a verification email is also sent',
                                                                    icon: 'success',
                                                                }).then(() => {
                                                                    this.setState({ loading: false })
                                                                })
                                                            });
                                                    }).catch((error) => {
                                                        var errorMessage = error.message;
                                                        Swal.fire({
                                                            title: 'Oops!',
                                                            text: errorMessage,
                                                            icon: 'error',
                                                        }).then(() => {
                                                            this.setState({ loading: false })
                                                        })
                                                    });
                                                }).catch((error) => {
                                                    var errorMessage = error.message;
                                                    Swal.fire({
                                                        title: 'Oops!',
                                                        text: errorMessage,
                                                        icon: 'error',
                                                    }).then(() => {
                                                        this.setState({ loading: false })
                                                    })
                                                });

                                            }
                                        })
                                    }}>Edit</a>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Password</p>
                                    <div className='circle-container'>
                                        <div className='circle-password'></div>
                                        <div className='circle-password'></div>
                                        <div className='circle-password'></div>
                                        <div className='circle-password'></div>
                                        <div className='circle-password'></div>
                                        <div className='circle-password'></div>
                                        <div className='circle-password'></div>
                                    </div>
                                    <a href='javascript:void(0)' onClick={this.editPassword.bind(this, "edit-real")}>Edit</a>
                                </div>
                            </div>
                            <div className='inner-container-rcdp delaccount' style={{ position: "absolute", bottom: 10, left: 0, backgroundColor: "transparent" }}>
                                <div className='content-inner-crcdp'>
                                    <a href='https://www.google.com' style={{ color: "red" }}>Delete account</a>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <Footer />
            </div>
        )
    }
}

export default ProfileSettings