import React from 'react';
import { Link } from 'react-router-dom';
import './media.css';


const Media = ({ topline, header, paragraph, text, lin1, btnlink, btntxt}) => {
    return (
        
        <div className='media__container'>
            <div className='media__wrapper'>
                <h3>{topline}</h3>
                <h1>{header}</h1>
                <p>{paragraph}</p>
                <h5>{text}</h5>
                <Link className='linked' to={btnlink}><button>{btntxt}</button></Link>
            </div>
            <div className="image__wrapper">
                <img src={lin1} alt='Etheware' loading="lazy"/>
            </div>
        </div>
    );
}

export default Media;
