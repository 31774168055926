import React, { useRef, useEffect } from 'react';
import './waitlist.css'
import People from '../../assets/people.png';
import Nasa from '../../assets/nasa.png';
import './waitlist.css';
import emailjs from '@emailjs/browser';
import{ init } from '@emailjs/browser';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

init("user_Nv6WEeTowVFjmAASEGfpj");

function Waitlist() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_lhyl1pb", "template_rxv8e3r", form.current, "user_Nv6WEeTowVFjmAASEGfpj") 
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log('Failed, refresh your page and try again...', error);
          });
          e.target.reset();
          alert('Thank you for your submission, check your email for further instructions. - WIth Love, The Etheware Team');
      };

    return ( 
        <Col className="header">
            <div className="header__content">
                <h1 className="gradient__text">
                Join the Exclusive Etheware Open Access Waitlist
                </h1>
                <p>The Future is in your hands, take the opportunity</p>
                <h2 className='super__title'>Be the first to see, hear, and receive some of the newest technology never before seen. Big things are coming. Are you ready?</h2>
                <div className="header-content__input">
                    <form className="header-form" id="email-form" ref={form} onSubmit={sendEmail}>
                        <input type="name" 
                            name="full__name" 
                            placeholder="Full Name" 
                            required
                        />
                        <input type="email" 
                            name="email" 
                            placeholder="Email Address" 
                            required
                        />
                        <button className='wl-su_btn' type="submit" value="Send">Get Started</button>
                    </form>
                    <p> Join today while space lasts. if you're lucky, you will be able to join early on to our exclusive Early-Bird Research Team that focuses on Beta Testing new company applications, games, and technology. </p>
                    <p>Each member will receive their name and Research role accredited on each application's credits page which is public. In addition to an Etheware verified certificate mailed to you, along with vouchers for members only exclusive events and parties.</p>
                </div>
                <div className="header-content__people">
                    <img src={People} alt='4,901 people' loading="lazy"/>
                    <p>1,693 people have requested access in the last 48 hours</p>
                </div>
            </div>
            <div className="header__image">
                <img src={Nasa} alt='Planet Etheware' loading="lazy"/>
            </div>
        </Col>
        
       
    )
}

export default Waitlist;
