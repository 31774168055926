import React from 'react';
import { Contact, CTA, LeftMedia, Media, Navbar, TextBox } from '../../components';
import { Footer, Interactive } from '../../containers';

const Port4 = () => {
  return (
    <div className='home'>
        <Navbar />
        <LeftMedia
            topline={"Etheware presents the future"}
            header={"VM.CRYSTALWARE: Aligning Holographic systems with human intent"}
            paragraph={"VM.CRYSTALWARE is a subsidiary of Etheware Solutions with a focus on bridging the gap between AI and holographic technology. By utilizing the power of machines that are far more capable than us and neural networks within machines, we can help solve very hard scientific problems that humans are not capable of solving themselves to help further the human race and the limits to our communication."}
            text={"From building simple websites in highschool after class, to engineering the full concepts for interactive holograms at a global scale, this startup is anything but ordinary"}
            videolink={require('../../assets/port4-2.mp4')}
            buttonlink={'/company'}
            button={"Learn More"}
        />
         <Media 
          topline={"Alignment and Research"}
          header={"Business Overview"}
          paragraph={"Alignment research at VM.Crystalware focuses on building AR/VR systems to be useful, limitless, and secure. Our group is researching and creating ways for transforming holographic media from human input and at the moment provide services with our team at Etheware.com"}
          text={"Our long-term objective is to develop scalable solutions that will coordinate significantly more competent holographic systems in the future - a vital component of our mission."}
          lin1={require('../../assets/port4-1.png')}
        />
        <LeftMedia
            topline={"Where We Started"}
            header={"VM.Crystalware, The Start of It All: View The Demo"}
            paragraph={"We made a a viewable demo page of our first ever landing page we drafted for the concept of Etheware. You can take a look at our first ever landing page built under the alias, VM.Crystalware. The Etheware theme derives itself from that of the original theme and UI/UX originated from the single page demo app VM.Crystalware."}
            text={"Click Below to Learn More"}
            videolink={require('../../assets/port4-5.mp4')}
            buttonlink={'https://vmcrystalware-demo-webapp.web.app' }
            button={"Show Me the Demo"}
        />
          <TextBox
          title={"The Future of VM.Crystalware"}
          text={"Set to launch officially in 2028, VM.Crystalware is innovating technology that reflects pictures from high-definition video projectors using a specially constructed foil through an open system. This allows for the display of holograms of varying sizes and unique clarity manufactured using proprietary software on any device with connectivity. The complete system is made up of a structural glass pyramid a stage where the virtual image is shown."}
          subtitle={"The vision of the future has never been so crystal clear with the Liquid Scenic server supplied uncompressed material for three layered 2040 x 1280 pictures, giving 94,000 lumens of extraordinarily clear projected vision for the images, visible in even the brightest conditions."}
        />
        <Interactive />
        <CTA />
        <Contact />
        <Footer />
    </div>
  )
}

export default Port4