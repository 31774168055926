import React from 'react'
import './pe.css';

const PE = () => {
  return <div className='innovations__wrapper d-flex gap-5'>
      <table className='table'>
      <thead>
          <tr>
              <th>Institute</th>
              <th>Department</th>
              <th>Certification</th>
              <th>Year</th>
          </tr>
      </thead>
      <tbody>
            <tr>
              <td>Google</td>
              <td>Development</td>
              <td>Google Developer Certified Agency</td>
              <td>2020-Present</td>
            </tr>
          <tr>
              <td>Microsoft</td>
              <td>Business Solutions</td>
              <td>Microsoft Certified Partner</td>
              <td>2019-Present</td>
          </tr>
          <tr>
              <td>Hyper-ledger</td>
              <td>Blockchain</td>
              <td>Hyper-ledger Certified Service Provider</td>
              <td>2020-Present</td>
          </tr>
          
          <tr>
              <td>Vue</td>
              <td>Development</td>
              <td>Vue.js Enterprise Partners</td>
              <td>2021-Present</td>
          </tr>        
          <tr>
              <td>Cisco</td>
              <td>Networking</td>
              <td>Cisco Certified Network Analyst</td>
              <td>2021-Present</td>
          </tr>
          <tr>
              <td>Roku</td>
              <td>Development</td>
              <td>Roku Development Partner</td>
              <td>2022-Present</td>
          </tr>
      </tbody>
  </table>
  </div>
  
}

export default PE