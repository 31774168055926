import React from 'react'
import { Navbar } from '../../components';


const PP = () => {
  return (
    
    <div className='home'>
        <Navbar />
        <div className="terms-of-service">
            <a href='/legal'><button>Back</button></a>
            <h1>Privacy Policy</h1>
            <h6>Updated Mar 7, 2022</h6>
            <div className="text">
                <h2>Customer Privacy Policy</h2>
                <h5>Consent</h5>
                <p>At Etheware, accessible from www.etheware.com, one of our main priorities is the privacy of our visitors. 
                    This Privacy Policy document contains types of information that is collected and recorded by Etheware and how we use it.
                    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                    This Privacy Policy applies only to our online activities and is valid for visitors to our applications with regards to the 
                    information that is shared and/or collected at Etheware.com. This policy is not applicable to any information collected offline 
                    or via channels other than this website.  By using our website, you hereby consent to our Privacy Policy and agree to its terms.

                </p>
                <h5>Information We May Collect</h5>
                <p>We may collect three main types of information related to you or your 
                    use of our products and services:
                    Information from or about you
                    Information from or about your Etheware solution
                    Information from or about your application or service
                    Depending on the Etheware products and services you request, own, or 
                    use, not all of these types of information may be applicable to you.
                </p>
                <h5>How we use your information</h5>
                <p>We use the information we collect in various ways, including to:
                    Provide, operate, and maintain our services
                    Improve, personalize, and expand our applications
                    Understand and analyze how you use our services
                    Develop new products, services, features, and functionality
                    Communicate with you, either directly or through one of our partners, including for customer service, to provide you with 
                    updates and other information relating to the website, and for marketing and promotional purposes
                    Protect your information
                    Send emails with your consent
                    Find and prevent fraud
                </p>
                <p>Etheware follows a standard procedure of using log files. These files log visitors 
                    when they visit websites. All software companies do this as part of hosting service analytics. 
                    The information collected by log files include internet protocol (IP) addresses, browser type, 
                    Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the 
                    number of clicks. These are not linked to any information that is personally identifiable. The
                    purpose of the information is for analyzing trends, administering the site, tracking users' 
                    movement on the website, and gathering demographic information.
                    Like any other website, Etheware uses 'cookies'. These cookies are used to store information including visitors' preferences, 
                    and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by 
                    customizing our web page content based on visitors' browser type and/or other information. 
                    This information is protected and not shared with anyone other than Etheware.com.
                </p>
                <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Etheware.
                    Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their 
                    respective advertisements and links that appear on Etheware, which are sent directly to users' browsers. 
                    They automatically receive your IP address when this occurs. These technologies are used to measure the 
                    effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on
                    websites that you visit. Note that Etheware has no access to or control over these cookies that are used by third-party advertisers.
                    Etheware's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies 
                    of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                    You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management 
                    with specific web browsers, it can be found at the browsers' respective websites.
                </p>
                <p>Under the CCPA, among other rights, California consumers have the right to:
                    Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal 
                    data that a business has collected about consumers. Request that a business delete any personal data about the consumer 
                    that a business has collected.
                    Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                    </p>
                <h2>Privacy Policy Questions?</h2>
                <h5>Contact Us</h5>
                <p>For privacy questions or comments, to opt out from 
                    certain services, or to submit a data privacy request, please
                </p>
                <p>Email us at</p>
                <h6><a href={"mailto:solutions@etheware.com"}>support@etheware.com</a></h6>
            </div>
        </div>
    </div>
  )
}

export default PP;