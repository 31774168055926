import React from 'react';
import { Contact, CTA, LeftMedia, Media, Navbar, ProductImg, TextBox } from '../../components';
import { Footer } from '../../containers';

const Port2 = () => {
  return (
    <div className='home'>
        <Navbar />
        <LeftMedia
          topline={"Sleek and Powerful"}
          header={"Business Overview"}
          paragraph={"We were hired to create an IoT web application with E-commerce capabilities for automated and smart checkout by establishing a system to read insights on each item when a consumer leaves the store. "}
          text={"We traveled alongside them on their path and realized their idea in the form of an IoT-based smart checkout App — a personalized smart tool you'll always use in your business."}
          videolink={require('../../assets/port2-5.mp4')}
          buttonlink={'/contact'}
          button={"Create an App"}
        />
        <Media 
          topline={"Trials of the Vision"}
          header={"The Challenge"}
          paragraph={"Interestingly, the most significant task was to use sensors such as beacons to provide a more engaging in-store buying experience using beacon powered applications, sensor-based asset tracking, interactive digital signage panels, and connected online purchasing apps. One tough job was integrating IoT devices into their day-to-day operation, which required innovation, preparation, and vision. "}
          text={"Creating an automated checkout system using IoT devices saves money and minimizes staff requirements and stress by 75%"}
          lin1={require('../../assets/port2-1.png')}
        />
        <LeftMedia
          topline={"The Two-Door Roadster"}
          header={"The Solution"}
          paragraph={"IoT was used to successfully develop a checkout system that accounts for each product and automatically deducts the amount from the consumers' mobile payment app. Device and data security, including device authentication, confidentiality, and data integrity, has been enhanced."}
          text={"We used IoT-based data management to better understand user wants and forecast customer purchasing behavior for product planning."}
          videolink={require('../../assets/port2-3.mp4')}
          buttonlink={'/contact'}
          button={"Learn More"}
        />
        <Media 
          topline={"Evolution of EV"}
          header={"How Does It Work?"}
          paragraph={"Our solution supported in the real-time tracking of assets and orders across the supply chain, as well as the optimization of inventory management. Furthermore, we put in total user information security, including protection from viruses and the actions of hackers and other criminals. We recognized user requirements and intertwined predictive consumer purchasing behavior for product planning. With real-time alerts and security, we reduced the danger of unfulfilled insurance and other fee payments."}
          text={"You can now conduct seamless payments without fear of malware breach, enhance the user experience and track insights benefitting both the enterprise and client."}
          lin1={require('../../assets/port2-3.png')}
        />
         <ProductImg 
            title= {"Mercedes Benz AMG-GT 2022 - Vehicle Concept"}
            subtitle={"The future of Vehicles should foresee a driving experience unlike any other by improving the system to be intuitively sensitive to a users wants with AI neural networks to predict and prevent accidents, faster routes, and events."}
            imgUrl={require('../../assets/port2.png')}
        />
        <TextBox
          title={"Results"}
          text={"IoT was used to successfully develop a checkout system that counts the products and automatically deducts the amount from the consumers' mobile payment app like Apple Pay. And, with IoT-based digital asset monitoring, you can follow assets, orders, and inventory along the supply chain in real-time, allowing for more efficient inventory management."}

        />
        <CTA />
        <Contact />
        <Footer />
    </div>
  )
}

export default Port2