import React, { Component } from 'react'
import LeftDashboard from '../../components/LeftDashboard'
import "./index.css"
import firebase from "../../Config/Firebase"
import { Navbar } from '../../components';
import { Footer } from '../../containers';

export class Dashboard extends Component {

    async componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                console.log(user.emailVerified, '/idhar dekho bachooo')
            } else {
                // No user is signed in.
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className='container-dashboard-page'>
                    <div className='left-container-dp'>
                        <LeftDashboard onDashboard={true} />
                    </div>
                    <div className='right-container-dp'>
                        <h4 className='heading-to-hidemobile'>Dashboard</h4>
                        <div className='container-rcdp'>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Order an Etheware solution</p>
                                    <p className='desc-bgicrc'>Create next-gen apps to power your life</p>
                                    <a href='/store'>View Store</a>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp reserveimagebg'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Reserve a Specialist</p>
                                    <p className='desc-bgicrc'>Speak to an Etheware Specialist</p>
                                    <p className='desc-bgicrc'>Speak with an Etheware Specialist regarding your questions and concerns. </p>
                                    <br />
                                    <a href='https://calendly.com/etheware/specialist-help'>Book now</a>
                                </div>
                            </div>
                            <div className='inner-container-rcdp'>
                                <div className='bg-inner-crcdp purchasedapplication'>

                                </div>
                                <div className='content-inner-crcdp'>
                                    <p className='title-bgicrc'>Third Party?</p>
                                    <p className='desc-bgicrc'>Purchased an application with third party?</p>
                                    <br />
                                    <a href='/contact'>Check Trade In Value</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Dashboard