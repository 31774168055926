import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './execution.css'

const executionData = [
    {
        step: '1',
        title: 'Discovery Workshop',
        desc: 'After you join an Etheware Plan, we run a comprehensive analysis of your business, goals, pain points and priorities. These multiple brainstorming sessions help us to map out the existing workflows and your application and enterprise.'
    },
    {
        step: '2',
        title: 'Strategy and Solution Design',
        desc: 'At this stage, once we gather your requirements in a whiteboard session, we begin designing, prototyping, and user testing your platform or product.'
    },
    {
        step: '3',
        title: 'Development',
        desc: 'Programming and coding based on the designs approved by you takes place in this stage. Our development lifecycle consists of 3-stages: Alpha phase, Beta phase and Release phase.'
    }
]
const executionData2 = [
    {
        step: '4',
        title: 'Testing and QA',
        desc: 'We continue our data-derived validation process, carry out in-depth QA testing, for each release of your product or platform to ensure that the final product, that is ready for the market.'
    },
    {
        step: '5',
        title: 'Launch and Maintenance',
        desc: 'Here, your product or platform goes LIVE. On your approval, our devs publish your product in live environments. We exclusively take care of cloud and on-premise hosting.'
    },
    {
        step: '6',
        title: 'Support and Customer Service',
        desc: 'To ensure continuous improvement, we perform ongoing product and platform maintenance and optimization, deploy market strategies, and provide real/fix support.'
    }
]

const Execution = () => {
  return <section>
      <Container>
          <Row>
              <Col lg='12'>
                  <h1>The Execution Process</h1>
              </Col>
              <Col lg='6'>
                <div className="single__execution-container">
                    {executionData.map((item, index)=>
                        <div className="single__execution-content">
                            <div className="single__execution" key={index}>
                                <span className='execution__icon'><i class="ri-flask-line"></i></span>
                                <h3>{item.step}</h3>
                                <h2>{item.title}</h2>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    )}
                </div>
              </Col>
              <Col lg='6'>
                <div className="single__execution-container">
                    {executionData2.map((item, index)=>
                        <div className="single__execution-content">
                            <div className="single__execution" key={index}>
                                <span className='execution__icon'><i class="ri-flask-line"></i></span>
                                <h3>{item.step}</h3>
                                <h2>{item.title}</h2>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    )}
                </div>
              </Col>
          </Row>
      </Container>

  </section>
}

export default Execution