import React from 'react';
import { Navbar } from '../../components';
import { Footer } from '../../containers';
import './tos.css';


const TOS = () => {
  return (
      <div className='home'>
          <Navbar />
        <div className='terms-of-service'>
            <a href='/legal'><button>Back</button></a>
            <h1>Terms of Use</h1>
            <h6>Legal Information & Notices</h6>
            <div className="text">
                <h5>Ownership of Site; Agreement to Terms of Use</h5>
                <p>These Terms and Conditions of Use (the "Terms of Use") apply to the Etheware web site located 
                    at www.etheware.com, and all associated sites linked to www.etheware.com by Etheware, its 
                    subsidiaries and affiliates, including Etheware sites around the world (collectively, the "Site"). 
                    The Site is the property of Etheware LLC. ("Etheware") and its licensors. BY USING THE SITE, YOU AGREE 
                    TO THESE TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE.
                </p>
                <p>Etheware has the right to amend, modify, add, or remove elements of these Terms of Use
                    at any time. It is your duty to review these Terms of Service for modifications on a regular 
                    basis. Your continued use of the Site after the modifications are posted indicates that you acknowledge
                    and consent to the changes. Etheware provides you a personal, non-exclusive, non-transferable, limited 
                    license to visit and use the Site as long as you comply with these Terms of Use.
                </p>
                <h5>Content</h5>
                <p>All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, 
                    artwork and computer code (collectively, "Content"), including but not limited to the design, structure, 
                    selection, coordination, expression, "look and feel" and arrangement of such Content, contained on the 
                    Site is owned, controlled or licensed by or to Etheware, and is protected by trade dress, copyright, patent and 
                    trademark laws, and various other intellectual property rights and unfair competition laws.
                </p>
                <p>Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied, reproduced, 
                    republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way 
                    (including "mirroring") to any other computer, server, Web site or other medium for publication or distribution 
                    or for any commercial enterprise, without Etheware's express prior written consent.
                </p>
                <p>You may use information on Etheware products and services (such as data sheets, knowledge base articles,
                    and similar materials) purposely made available by Etheware for downloading from the Site, provided
                    that you (1) not remove any proprietary notice language in all copies of such documents, (2) use such 
                    information only for your personal, non-commercial informational purpose and do not copy or post such 
                    information on any networked computer or broadcast it in any media, (3) make no modifications to any such information,
                    and (4) not make any additional representations or warranties relating to such documents.
                </p>

                <h5>Your Use of the Site</h5>
                <p>You may not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, 
                    algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any 
                    portion of the Site or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Site or any Content, 
                    to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Site. Etheware 
                    reserves the right to bar any such activity.
                </p>
                <p>You may not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or networks connected to the Site or to any 
                    Etheware server, or to any of the services offered on or through the Site, by hacking, password "mining" or any other illegitimate means.
                </p>
                <p>You may not probe, scan or test the vulnerability of the Site or any network connected to the Site, nor breach the security or authentication measures on the 
                    Site or any network connected to the Site. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Site, or
                    any other customer of Etheware, including any Etheware account not owned by you, to its source, or exploit the Site or any service or information made available
                    or offered by or through the Site, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, 
                    other than your own information, as provided for by the Site.
                </p>
                <p>You agree that you will not take any action that imposes an unreasonable or disproportionately 
                    large load on the infrastructure of the Site or Etheware's systems 
                    or networks, or any systems or networks connected to the Site or to Etheware.
                </p>
                <p>You undertake not to use any device, program, or routine to interfere or try to interfere 
                    with the correct operation of the Site or any transaction conducted on the 
                    Site, or with the use of the Site by any other person.
                </p> 
                <p>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any 
                    message or transmittal you send to Etheware on or through the 
                    Site or any service offered on or through the Site. You may not pretend that you are, or 
                    that you represent, someone else, or impersonate any other individual or entity.
                </p>
                <p>You may not use the Site or any Content for any purpose that is unlawful or prohibited by t
                    hese Terms of Use, or to solicit the performance of any illegal activity or other 
                    activity which infringes the rights of Etheware or others.
                </p>

                <h5>Purchases; Other Terms and Conditions</h5>
                <p>Additional terms and conditions may apply to purchases of goods or services and to specific portions or 
                    features of the Site, including contests, promotions or other similar 
                    features, all of which terms are made a part of these Terms of Use by this reference. You agree
                     to abide by such other terms and conditions, including where applicable 
                    representing that you are of sufficient legal age to use or participate in such service or feature.
                     If there is a conflict between these Terms of Use and the terms posted 
                    for or applicable to a specific portion of the Site or for any service offered on or through the Site,
                     the latter terms shall control with respect to your use of that portion 
                    of the Site or the specific service.
                </p>
                <p>Etheware's obligations, if any, with regard to its products and services are governed solely by the 
                    agreements pursuant to which they are provided, and nothing on this Site should be construed to alter such agreements.</p>
                <p>Etheware may make changes to any products or services offered on the Site, or to the applicable prices for 
                    any such products or services, at any time, without notice. The materials on the Site with respect to products and 
                    services may be out of date, and Etheware makes no commitment to update the materials on the Site with respect to such products and services.
                </p>
                <p>Policies may be changed from time to time and are effective immediately upon posting such changes on the Site.</p>

                <h5>Accounts, Passwords and Security</h5>
                <p>Certain features or services offered on or through the Site may require you to open an account 
                    (including setting up an Etheware ID and password). You are entirely responsible for maintaining the confidentiality of the 
                    information you hold for your account, including your password, and for any and all activity that occurs under your account as a 
                    result of your failing to keep this information secure and confidential. You agree to notify 
                    Etheware immediately of any unauthorized use of your account or password, or any other breach of security. You may be held 
                    liable for losses incurred by Etheware or any other user of or visitor to the Site due to someone 
                    else using your Etheware ID, password or account as a result of your failing to keep your account information secure and confidential.
                </p>
                <p>You may not use anyone else's Etheware ID, password or account at any time without the express permission and consent of the holder of that Etheware ID, password or account. Etheware cannot and will not be liable for any loss or damage arising
                    from your failure to comply with these obligations.
                </p>

                <h5>Privacy</h5>
                <p>Etheware's Privacy Policy applies to use of this Site, and its terms are made a part of these Terms of Use by this 
                    reference. Additionally, by using the Site, you acknowledge and agree that Internet 
                    transmissions are never completely private or secure. You understand that any message or 
                    information you send to the Site may be read or intercepted by others, even if there is a special notice 
                    that a particular transmission (for example, credit 
                    card information) is encrypted.
                </p>

                <h5>Links to Other Sites and to the Etheware Site</h5>
                <p>This Site may contain links to other independent third-party Web sites ("Linked Sites"). These Linked Sites 
                    are provided solely as a convenience to our visitors. Such Linked Sites are not under Etheware's control, and Etheware is not responsible for 
                    and does not endorse the content of such Linked Sites, including any information or materials contained 
                    on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.
                </p>

                <h5>Disclaimers</h5>
                <p>ETHEWARE DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR 
                    UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS 
                    CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE IS SUBJECT TO 
                    CHANGE WITHOUT NOTICE. ETHEWARE CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR 
                    CONTAMINATION OR DESTRUCTIVE FEATURES. ETHEWARE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, 
                    NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. ETHEWARE DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS,
                    OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY ETHEWARE SERVICES. 
                    YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST ETHEWARE FOR DISSATISFACTION WITH THE SITE 
                    OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
                </p>
                <p>The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, 
                    computer virus, communication line failure, theft or destruction of or unauthorized 
                    access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action.
                </p>
                <p>Etheware reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or 
                    access to the Site, or any portion of the Site, for any reason; (2) to modify or change the Site, or any portion of the Site, 
                    and any applicable policies or terms; and (3) to interrupt the operation of the Site, or any portion of the Site, as necessary to
                    perform routine or non-routine maintenance, error correction, or other changes.
                </p>

                <h5>Limitation of Liability</h5>
                <p>Except where prohibited by law, in no event will Etheware be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost 
                    profits, even if Etheware has been advised of the possibility of such damages.
                </p>
                <p>If, notwithstanding the other provisions of these Terms of Use, Etheware is found to be liable to you for any damage or loss which arises out of or is in any way connected
                     with your use of the Site or any Content, Etheware's liability shall in no event exceed the greater of (1) 1/3 the total of any subscription or similar fees with respect 
                     to any service or feature of or on the Site paid in the six months prior to the date of the initial claim made against Etheware (but not including the purchase price for any 
                     Etheware hardware or software products or any Etheware Support or similar support program), or (2) US$100.00. Some jurisdictions do not allow limitations of liability, 
                     so the foregoing limitation may not apply to you.
                </p>

                <h5>Indemnity</h5>
                <p>You agree to indemnify and hold Etheware, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless
                    from any demands, loss, liability, claims or expenses (including attorneys' fees), made against Etheware by any third party due to or arising out of or in connection with your use of the Site.
                </p>

                <h5>Violation of These Terms of Use</h5>
                <p>Etheware may disclose any information we have about you (including your identity) if we determine that such disclosure is necessary in connection with any investigation or complaint 
                    regarding your use of the Site, or to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) Etheware's 
                    rights or property, or the rights or property of visitors to or users of the Site, including Etheware's customers. Etheware reserves the right at all times to disclose any information that Apple deems 
                    necessary to comply with any applicable law, regulation, legal process or governmental request. 
                    Etheware also may disclose your information when Etheware determines that applicable law requires or permits such disclosure, including exchanging information with other companies and 
                    organizations for fraud protection purposes.
                </p>
                <p>You acknowledge and agree that Etheware may preserve any transmittal or communication by you with Etheware through the Site or any service offered on or through the Site, and may also disclose such data 
                    if required to do so by law or Etheware determines that such preservation or disclosure is reasonably necessary to (1) comply with legal process, (2) enforce these Terms of Use, (3) respond to claims that 
                    any such data violates the rights of others, or (4) protect the rights, property or personal safety of Etheware, its employees, users of or visitors to the Site, and the public.
                </p>
                <p>You agree that Etheware may, in its sole discretion and without prior notice, terminate your access to the Site and/or block your future access to the Site if we determine that you have 
                    violated these Terms of Use or other agreements or guidelines which may be associated with your use of the Site. You also agree that any violation by you of these Terms of Use will 
                    constitute an unlawful and unfair business practice, and will cause irreparable harm to Etheware, for which monetary damages would be inadequate, and you consent to Etheware obtaining any 
                    injunctive or equitable relief that Etheware deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Etheware may have at law or in equity.
                </p>
                <p>You agree that Etheware may, in its sole discretion and without prior notice, terminate your access to the Site, for cause, which includes (but is not limited to) (1) requests by law 
                    enforcement or other government agencies, (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification of the Site or any service offered on or 
                    through the Site, or (4) unexpected technical issues or problems.
                </p>
                <p>If Etheware does take any legal action against you as a result of your violation of these Terms of Use, Etheware will be entitled to recover from you, and you agree to pay, all reasonable attorneys' fees and costs of such action, 
                    in addition to any other relief granted to Etheware. You agree that Etheware will not be liable to you or to any third party for termination of your access to the Site as a result of any violation of these Terms of Use.
                </p>
                <h5>Governing Law; Dispute Resolution</h5>
                <p>You agree that all matters relating to your access to or use of the Site, including all disputes, will be governed by the laws of the United States and by the laws of the State of California without regard to its conflicts of laws 
                    provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in Santa Clara County, California, and waive any objection to such jurisdiction or venue. The preceding provision regarding venue does 
                    not apply if you are a consumer based in the European Union. If you are a consumer based in the European Union, you may make a claim in the courts of the country where you reside. Any claim under these Terms of Use must be brought 
                    within one (1) year after the cause of action arises, or such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to this limitation. No recovery 
                    may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys' fees. In the event of any controversy or dispute between Etheware and you arising out 
                    of or in connection with your use of the Site, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), 
                    then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.
                </p>

                <h5>Void Where Prohibited</h5>
                <p>Etheware administers and operates the www.etheware.com Site from its location in San Diego, California USA; other Etheware sites may be administered and operated from various locations outside the United States. 
                    Although the Site is accessible worldwide, not all features, products or services discussed, referenced, provided or offered through or on the Site are available to all persons or in all geographic locations, or 
                    appropriate or available for use outside the United States. Etheware reserves the right to limit, in its sole discretion, the provision and quantity of any feature, product or service to any person or geographic 
                    area. Any offer for any feature, product or service made on the Site is void where prohibited. If you choose to access the Site from outside the United States, you do so on your own initiative and you are solely 
                    responsible for complying with applicable local laws.
                </p>

                <h5>Miscellaneous</h5>
                <p>You may not use or export or re-export any Content or any copy or adaptation of such Content, or any product or service offered on the Site, in violation of any applicable laws or regulations, including without 
                    limitation United States export laws and regulations.
                </p>
                <p>If any of the provisions of these Terms of Use are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum extent 
                    necessary and replaced with a valid provision that best embodies the intent of these Terms of Use, so that these Terms of Use shall remain in full force and effect. These Terms of Use constitute the entire agreement 
                    between you and Etheware with regard to your use of the Site, and any and all other written or oral agreements or understandings previously existing between you and Etheware with respect to such use are hereby superseded and 
                    cancelled. Other than as provided in a purchase agreement you enter into with Etheware, Etheware will not accept any counter-offers to these Terms of Use, and all such offers are hereby categorically rejected. Etheware's failure
                     to insist on or enforce strict performance of these Terms of Use shall not be construed as a waiver by Etheware of any provision or any right it has to enforce these Terms of Use, nor shall any course of conduct between 
                    Etheware and you or any other party be deemed to modify any provision of these Terms of Use. These Terms of Use shall not be interpreted or construed to confer any rights or remedies on any third parties.
                </p>
                <p>Etheware provides access to Etheware international data and, therefore, may contain references or cross references to Etheware products, programs and services that are not announced in your country. Such reference does not 
                    imply that Etheware in your country intends to announce such products, programs or services.
                </p>

                <p>The information contained in this web site is subject to change without notice.
                    Copyright © 2022-2023 Etheware LLC. All rights reserved.
                    Etheware LLC., San Diego, CA, USA.
                </p>

                <p>Updated by The Etheware Legal Team on Jul. 13, 2022</p>

                <h5>Feedback and Information</h5>
                <p>Any feedback you provide at this site shall be deemed to be non-confidential. Etheware shall be free to use such information on an unrestricted basis.</p>
            </div>
            <div className="text">
                <h2>Governing Law</h2>
                <h5>Disclaimer</h5>
                <p>Permission is not granted to temporarily download one copy of the materials on 
                    Etheware's Website for personal, or commercial transitory viewing. 
                    This is the grant of a license, not a transfer of title, and under this license you may not:
                    modify or copy the materials;
                    use the materials for any commercial purpose or for any public display;
                    attempt to reverse engineer any software contained on Etheware's Website;
                    remove any copyright or other proprietary notations from the materials; or
                    transferring the materials to another person or "mirror" the materials on any other server.
                    All the materials on www.etheware.com are provided "as is". Due to an increase in international cyber-attacks 
                    on technology companies, Etheware is not liable for any funds lost on the www.etheware.com website. 
                    Etheware makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, 
                    Etheware 
                    does not make any representations concerning the accuracy or reliability of the use of the materials on 
                    its Website or otherwise relating to such materials or any sites linked to this Website.
                </p>
                <h5>Etheware NDA</h5>
                <p>To preserve a private development environment inside each project, we need our clients to sign an 
                    NDA (Non-Disclosure Agreement). We have open and transparent discussions 
                    on security and other subjects that affect us. This topic covers the quantity of data that must be 
                    gathered, who will have access to the information, and the hazards that 
                    may be associated. At the time of recruitment, we also have each of our personnel 
                    sign a Non-Disclosure Agreement (NDA). We make it clear that breaking the agreement will result 
                    in legal action and termination. We remind staff about the importance of data security on a regular 
                    basis and give updated procedures to guarantee that corporate standards are not jeopardized.
                </p>
                <p>Email us at</p>
                <h6><a href={"mailto:solutions@etheware.com"}>support@etheware.com</a></h6>
                </div>
            </div>
        <Footer />
      </div>
    
  )
}

export default TOS